import { useEffect, useState } from "react";
import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import filterImage from "../../assets/images/filter.png";
import arrowImage from "../../assets/images/sorting-arrows.png";
import { SearchFlight } from "../../components/forms/flight/SearchFlight";
import { TalkToAgents } from "../../components/TalkToAgent";
import { SidebarFilter } from "../../components/forms/flight/SidebarFilter";
// import { useNavigate } from "react-router-dom";
import moment from "moment";
import LazyImage from "../../pages/LazyImage";

import {
  expressSearch,
  getTUItokenAfterExpressSearch,
} from "../../api/FlightAPI";
import { useFlightLoader } from "../../components/forms/shared/useFlightLoader";
import { RoundTripFastestFlightsList } from "../../components/forms/flight/RoundTripFastest";
import { formatFlightClass } from "../../utils/helpers";
// import loadedFlightIndication from "../../assets/images/loaded-flights.gif";
import { toast } from "react-toastify";
import { HotelPlusFlight } from "../../components/forms/hotel-and-flight/HotelPlusFlight";
import { RoundTripFlightsList } from "../../components/forms/flight/CheapestRound";
import { slabsList } from "../../api/AdminAPI";

export const RoundTripFlightSearch = () => {
  const [flightType, setFlightType] = useState("cheapest");
  const [flightListHistory, setFlightLisHistory] = useState<any>([]);
  const [flightReturnHistory, setFlightReturnHistory] = useState<any>([]);
  const [slabsListData, setSlabsListData] = useState<any>([]);

  const [flightListHistorySpecial, setFlightLisHistorySpecial] = useState<any>(
    []
  );
  const [flightReturnHistorySpecial, setFlightReturnHistorySpecial] =
    useState<any>([]);

  const [selectedArrivalTimes, setSelectedArrivalTimes] = useState<string[]>(
    []
  );

  // const [flightDetails, setFlightDetails] = useState([null, null]);
  const [startPolling, setStartPolling] = useState(false);
  const [flightIndication, setFlightIndication] = useState(false);

  const [checkIfTripsNotEmpty, setCheckIfTripsNotEmpty] = useState(true);

  //filter flight states
  const [priceRange, setPriceRange] = useState<number>(0);
  const [getHightestPrice, setGetHightestPrice] = useState<number>(0);

  const [upToFlights, setUpToFlights] = useState(getHightestPrice);
  const [minPriceRange, setMinPriceRange] = useState<number>(priceRange);

  const [toggleFLightPrice, setToggleFlightPrice] = useState(false);
  const [refundable, setRefundable] = useState(false);
  const [otherFare, setOtherFare] = useState(false);
  const [selectedStops, setSelectedStops] = useState<any>([]);

  // const [firstSelectedFlight, setFirstSelectedFlight] = useState<any>();

  const [selectedFlights, setSelectedFlights] = useState<string[]>([]);
  const [selectedArrivalAirport, setSelectedArrivalAirport] = useState<any[]>(
    []
  );
  const [selectedDepartureTimes, setSelectedDepartureTimes] = useState<
    string[]
  >([]);

  const { isFlightLoading, setIsFlightLoading } = useFlightLoader();

  const getCurrentSelectedTab = localStorage.getItem("tabSearchData");

  useEffect(() => {
    const flightDataJSON = localStorage.getItem("userFlightSearchData");
    const flightData = JSON.parse(String(flightDataJSON));

    if (flightData) {
      const getFlights = async () => {
        await expressSearch({
          ADT: flightData.ADT,
          CHD: flightData.CHD,
          INF: flightData.INF,
          Cabin: String(formatFlightClass(flightData.Cabin)),
          Source: "CF",
          //  Source: "LV",
          Mode: "AS",
          TUI: "",
          FareType: flightData.flightWay === "one way" ? "ON" : "RT",
          ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
          IsMultipleCarrier: false,
          IsRefundable: false,
          SecType: "",
          YTH: 0,
          Trips: [
            {
              From: flightData.FromCode,
              To: flightData.ToCode,
              ReturnDate:
                flightData.ReturnDate !== "" &&
                flightData.flightWay !== "one way"
                  ? moment(flightData.ReturnDate).format("YYYY-MM-DD")
                  : "",
              OnwardDate:
                flightData.OnwardDate !== ""
                  ? moment(flightData.OnwardDate).format("YYYY-MM-DD")
                  : "",
            },
          ],
          Parameters: {
            Airlines: "",
            GroupType: "",
            Refundable: "",
            IsDirect: flightData.IsDirect,
            IsStudentFare: flightData.IsStudentFare,
            IsNearbyAirport: flightData.IsNearbyAirport,
            IsSeniorCitizen: flightData.IsSeniorCitizen,
          },
        })
          .then((response) => {
            localStorage.setItem("exp-token", response.data.TUI);
            setStartPolling(true);
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              toastId: "forGeoLocation",
            });
          });
      };

      const getFlightsSpecial = async () => {
        await expressSearch({
          ADT: flightData.ADT,
          CHD: flightData.CHD,
          INF: flightData.INF,
          Cabin: String(formatFlightClass(flightData.Cabin)),
          // Source: "CF",
          Source: "LV",
          Mode: "AS",
          TUI: "",
          FareType: "RS",
          ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
          IsMultipleCarrier: false,
          IsRefundable: false,
          SecType: "",
          YTH: 0,
          Trips: [
            {
              From: flightData.FromCode,
              To: flightData.ToCode,
              ReturnDate:
                flightData.ReturnDate !== "" &&
                flightData.flightWay !== "one way"
                  ? moment(flightData.ReturnDate).format("YYYY-MM-DD")
                  : "",
              OnwardDate:
                flightData.OnwardDate !== ""
                  ? moment(flightData.OnwardDate).format("YYYY-MM-DD")
                  : "",
            },
          ],
          Parameters: {
            Airlines: "",
            GroupType: "",
            Refundable: "",
            IsDirect: flightData.IsDirect,
            IsStudentFare: flightData.IsStudentFare,
            IsNearbyAirport: flightData.IsNearbyAirport,
            IsSeniorCitizen: flightData.IsSeniorCitizen,
          },
        })
          .then((response) => {
            localStorage.setItem("exp-token-special", response.data.TUI);
            setStartPolling(true);
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              toastId: "special-fare",
            });
          });
      };

      getFlights();
      getFlightsSpecial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pollApiUntilCondition = async () => {
    // const tuiJSON = localStorage.getItem("exp-token");
    setIsFlightLoading(true);
    setFlightIndication(false);
    // console.log("tuiJSON", tuiJSON);
    try {
      const tuiJSON = localStorage.getItem("exp-token");

      if (!tuiJSON) {
        console.log("Token not found in local storage");
        return;
      }

      const response = await getTUItokenAfterExpressSearch(String(tuiJSON));

      if (response.data.Trips !== null) {
        if (response.data.Trips[0]) {
          const updatedHistory = response.data.Trips[0]?.Journey.map(
            (journey: any) => ({
              ...journey,
              TUI: response.data.TUI,
            })
          );

          setFlightLisHistory((prevHistory: any) => [
            ...prevHistory,
            // ...updatedHistory,
            ...updatedHistory,
            // ...response.data.Trips[0]?.Journey,
          ]);
        }

        if (response.data.Trips[1]) {
          const updatedReturnHistory = response.data.Trips[1]?.Journey.map(
            (journey: any) => ({
              ...journey,
              TUI: response.data.TUI,
            })
          );

          setFlightReturnHistory((prevHistory: any) => [
            ...prevHistory,
            ...updatedReturnHistory,
          ]);
        }

        if (flightListHistory.length > 0 && flightReturnHistory.length > 0) {
          setIsFlightLoading(false);
        }

        localStorage.setItem(
          "flightJourney",
          JSON.stringify({
            tUI: response.data.TUI,
            trips: [...flightListHistory],
          })
        );
      }
      if (response.data.Completed === "True") {
        setCheckIfTripsNotEmpty(false);
        setIsFlightLoading(false);
        setFlightIndication(true);

        setTimeout(() => {
          setFlightIndication(false);
        }, 2000);
      } else {
        setTimeout(pollApiUntilCondition, 100);
      }
    } catch (err) {
      console.log("Error while polling:", err);
    }
  };

  const pollApiUntilConditionSpecialFare = async () => {
    const tuiJSON = localStorage.getItem("exp-token-special");

    try {
      const response = await getTUItokenAfterExpressSearch(String(tuiJSON));

      if (response.data.Trips !== null) {
        if (response.data.Trips[0]) {
          setIsFlightLoading(true);

          setTimeout(() => {
            setIsFlightLoading(false);
          }, 2000);

          const updatedHistorySpecial = response.data.Trips[0]?.Journey.map(
            (journey: any) => ({
              ...journey,
              flightType: "special",
              TUI: response.data.TUI,
            })
          );

          setFlightLisHistory((prevHistory: any) => [
            ...prevHistory,
            ...updatedHistorySpecial,
          ]);

          setFlightLisHistorySpecial((prevHistory: any) => [
            ...prevHistory,
            ...updatedHistorySpecial,
          ]);
        }

        if (response.data.Trips[1]) {
          const updatedHistoryReturnSpecial =
            response.data.Trips[1]?.Journey.map((journey: any) => ({
              ...journey,
              flightType: "special",
              TUI: response.data.TUI,
            }));

          setFlightReturnHistorySpecial((prevHistory: any) => [
            ...prevHistory,
            ...updatedHistoryReturnSpecial,
          ]);

          setFlightReturnHistory((prevHistory: any) => [
            ...prevHistory,
            ...updatedHistoryReturnSpecial,
          ]);
        }

        if (
          flightListHistorySpecial.length > 0 &&
          flightReturnHistorySpecial.length > 0
        ) {
          setIsFlightLoading(false);
        }

        localStorage.setItem(
          "flightJourney",
          JSON.stringify({
            tUI: response.data.TUI,
            trips: [...flightListHistorySpecial],
          })
        );
      }
      if (response.data.Completed === "True") {
        setCheckIfTripsNotEmpty(false);
        setIsFlightLoading(false);
      } else {
        setTimeout(pollApiUntilCondition, 100);
        setTimeout(pollApiUntilConditionSpecialFare, 100);
      }
    } catch (err) {
      console.log("Error while polling:", err);
    }
  };

  // const flightMerged = [flightListHistory[0], flightReturnHistory[0]];

  useEffect(() => {
    if (startPolling && checkIfTripsNotEmpty) {
      pollApiUntilCondition();
      pollApiUntilConditionSpecialFare();

      const timeout = setTimeout(() => {
        setIsFlightLoading(false);
      }, 30000);

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIfTripsNotEmpty, startPolling]);

  useEffect(() => {
    slabsList()
      .then((res) => {
        setSlabsListData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const returnFlightsMap = flightReturnHistory?.reduce(
    (acc: { [x: string]: any }, flight: { Index: string | number }) => {
      acc[flight.Index] = flight;
      return acc;
    },
    {}
  );

  const combinedFlights = flightListHistory?.map(
    (onwardFlight: { flightType: string; Index: string | number }) => {
      const returnFlight = returnFlightsMap[onwardFlight?.Index];
      if (
        returnFlight &&
        !(returnFlight.flightType === "special" && !onwardFlight.flightType)
      ) {
        return {
          OnwardFlight: onwardFlight,
          ReturnFlight: returnFlight,
        };
      }
      return {
        OnwardFlight: onwardFlight,
        ReturnFlight: null,
      };
    }
  );

  const filteredCombinedFlights = combinedFlights?.filter(
    (flightCombination: { ReturnFlight: null }) =>
      flightCombination.ReturnFlight !== null
  );

  const mappedSpecial = filteredCombinedFlights?.map(
    (d: any) => d.OnwardFlight.flightType === "special" && d
  );

  let getIndexFlightSearch: any;

  getIndexFlightSearch = otherFare ? mappedSpecial : filteredCombinedFlights;

  function removeDuplicatesByFlightNo(arr: any[]) {
    const uniqueFlightNos = new Set();

    return arr.filter((item: any) => {
      const onwardFlight = item?.OnwardFlight;
      if (onwardFlight && !uniqueFlightNos.has(onwardFlight.Index)) {
        uniqueFlightNos.add(onwardFlight.Index);
        return true;
      }
      return false;
    });
  }

  const airlines = [...removeDuplicatesByFlightNo(getIndexFlightSearch)];

  const allConnectingAirports = airlines.reduce((airports, flight) => {
    const connectingAirports = flight.OnwardFlight.Connections.map(
      (connection: { ArrAirportName: any }) =>
        connection.ArrAirportName?.split("|")[1]
    );

    return [...airports, ...connectingAirports];
  }, []);

  const allConnectingAirportsReturn = airlines.reduce((airports, flight) => {
    const connectingAirports = flight.ReturnFlight.Connections.map(
      (connection: { ArrAirportName: any }) =>
        connection.ArrAirportName?.split("|")[1]
    );

    return [...airports, ...connectingAirports];
  }, []);

  const uniqueConnectingAirportsSet = new Set(allConnectingAirports);

  const uniqueConnectingAirportsSetReturn = new Set(
    allConnectingAirportsReturn
  );

  const combineSetsConnectingArr = [
    ...Array.from(uniqueConnectingAirportsSet),
    ...Array.from(uniqueConnectingAirportsSetReturn),
  ];

  const uniqueConnectingAirports = Array.from(
    new Set(combineSetsConnectingArr)
  );

  useEffect(() => {
    const getPriceMax = () => {
      removeDuplicatesByFlightNo(getIndexFlightSearch)?.filter(() => {
        const highestPrice = Math.max(
          ...removeDuplicatesByFlightNo(getIndexFlightSearch).map(
            (flightl: any) =>
              // flightl.OnwardFlight.GrossFare + flightl.ReturnFlight.GrossFare
              flightl.OnwardFlight.NetFare + flightl.ReturnFlight.NetFare
          )
        );

        const lowestPrice = Math.min(
          ...removeDuplicatesByFlightNo(getIndexFlightSearch).map(
            (flightl: any) =>
              flightl.OnwardFlight.NetFare + flightl.ReturnFlight.NetFare
          )
        );
        const lowestPriceMin = Math.min(
          ...removeDuplicatesByFlightNo(getIndexFlightSearch).map(
            (flightl: any) =>
              flightl.OnwardFlight.NetFare + flightl.ReturnFlight.NetFare
          )
        );
        const priceRangeMax = highestPrice;
        setPriceRange(lowestPrice);
        // setMinPriceRange(lowestPriceMin);
        //  setMinPriceRange(lowestPriceMin);

        setUpToFlights(priceRangeMax);
        setGetHightestPrice(priceRangeMax);
        return priceRangeMax;
      });
    };
    getPriceMax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightListHistory]);

  const filteredFlightsList = removeDuplicatesByFlightNo(
    getIndexFlightSearch
  )?.filter(
    (flight: {
      OnwardFlight: {
        Duration: string;
        AirlineName: string;
        DepartureTime: string;
        ArrivalTime: string;
        NetFare: number;
        Connections: Array<any>;
        Refundable: string;
      };
      ReturnFlight: {
        NetFare: number;
        Connections: Array<any>;
        AirlineName: string;
      };
    }) => {
      const airlineName = flight.OnwardFlight.AirlineName?.split("|")[0];
      const airlineNameReturn = flight.ReturnFlight.AirlineName?.split("|")[0];
      const departureTimeList = moment(flight.OnwardFlight.DepartureTime);
      const departureHrs = departureTimeList.format("H");

      const arrivalTimeList = moment(flight.OnwardFlight.ArrivalTime);
      const arrivalHrs = arrivalTimeList.format("H");

      const priceValue =
        flight?.OnwardFlight.NetFare + flight?.ReturnFlight.NetFare;
      const priceRangeMin = toggleFLightPrice ? priceRange : getHightestPrice;

      const numberOfConnections =
        flight.OnwardFlight.Connections.length ||
        flight.ReturnFlight.Connections.length;
      const hasNoStopsSelected = selectedStops.length === 0;
      const hasZeroStopsSelected = selectedStops.includes("0");
      const hasOneStopSelected = selectedStops.includes("1");
      const hasMoreThanOneStopSelected = selectedStops.includes("1+");

      const isConnectionValid =
        (hasNoStopsSelected && numberOfConnections === 0) ||
        (hasZeroStopsSelected && numberOfConnections === 0) ||
        (hasOneStopSelected && numberOfConnections === 1) ||
        (hasMoreThanOneStopSelected && numberOfConnections > 1);

      const hasConnectionToAirports = flight.OnwardFlight.Connections.some(
        (connection: { ArrAirportName: string }) => {
          const airportsInConnection =
            connection && connection?.ArrAirportName?.split("|")[1]?.split(",");
          return airportsInConnection?.some((airport) =>
            selectedArrivalAirport.includes(airport.trim())
          );
        }
      );

      const hasConnectionToAirportsReturn =
        flight.ReturnFlight.Connections.some(
          (connection: { ArrAirportName: string }) => {
            const airportsInConnection =
              connection &&
              connection?.ArrAirportName?.split("|")[1]?.split(",");
            return airportsInConnection?.some((airport) =>
              selectedArrivalAirport.includes(airport.trim())
            );
          }
        );

      const timeSections: {
        [key: string]: { min: number; max: number };
      } = {
        "05-12": { min: 5, max: 12 }, // 5 am - 12 pm
        "12-18": { min: 12, max: 18 }, // 12 pm - 6 pm
        "18-23": { min: 18, max: 23 }, // 6 pm - 11 pm
        "23-05": { min: 23, max: 29 }, // 11 pm - 5 am (next day)
      };

      const isArrivalTimeValid =
        selectedArrivalTimes.length === 0 ||
        selectedArrivalTimes.some((selectedTime) => {
          const selectedArrivalTimeRange = timeSections[selectedTime];
          return (
            selectedArrivalTimeRange &&
            selectedArrivalTimeRange.min <= Number(arrivalHrs) &&
            Number(arrivalHrs) < selectedArrivalTimeRange.max
          );
        });

      const isDepartureTimeValid =
        selectedDepartureTimes.length === 0 ||
        selectedDepartureTimes.some((selectedTime) => {
          const selectedDepartureTimeRange = timeSections[selectedTime];
          const isValid =
            selectedDepartureTimeRange &&
            selectedDepartureTimeRange.min <= Number(departureHrs) &&
            Number(departureHrs) < selectedDepartureTimeRange.max;

          return isValid;
        });

      return (
        // Airline conditions
        (selectedFlights.length === 0 ||
          selectedFlights.includes(airlineName) ||
          selectedFlights.includes(airlineNameReturn)) &&
        // Departure time conditions
        isDepartureTimeValid &&
        // Arrival time conditions
        isArrivalTimeValid &&
        // Refundable
        (refundable ? flight.OnwardFlight.Refundable === "Y" : true) &&
        // Price and connection conditions
        priceValue <= getHightestPrice &&
        priceValue <= priceRangeMin &&
        // No. of stops and no connections condition
        (selectedStops.length === 0 || isConnectionValid) &&
        // Connected airports
        (selectedArrivalAirport.length === 0 ||
          hasConnectionToAirports ||
          hasConnectionToAirportsReturn)
      );
    }
  );

  useEffect(() => {
    window.scrollTo({
      top: 80,
      behavior: "smooth",
    });
  }, [filteredFlightsList]);

  // Cheapest flight sorting :
  const sortFlightsByFare = (flights: any[]) => {
    const filteredFlights = flights.filter((flight) => {
      return (
        flight.OnwardFlight.NetFare !== 0 && flight.ReturnFlight.NetFare !== 0
      );
    });

    // Sort the filtered flights by fare
    const sortedFlights = filteredFlights.slice().sort((a, b) => {
      return (
        a.OnwardFlight.NetFare +
        a.ReturnFlight.NetFare -
        (b.OnwardFlight.NetFare + b.ReturnFlight.NetFare)
      );
    });

    return sortedFlights;
  };

  const matchingFlights = filteredFlightsList?.filter(
    (flight: { OnwardFlight: { Duration: string } }) => {
      return flight?.OnwardFlight.Duration;
    }
  );
  const sortedFlightsCheapest = sortFlightsByFare(matchingFlights);

  const handleNoOfStopsChange = (stop: any) => {
    if (selectedStops.includes(stop)) {
      setSelectedStops(selectedStops.filter((s: any) => s !== stop));
    } else {
      setSelectedStops([...selectedStops, stop]);
    }
  };

  const handleArrivalTimeChange = (time: any) => {
    if (selectedArrivalTimes.includes(time)) {
      setSelectedArrivalTimes((prevTimes) =>
        prevTimes.filter((selectedTime) => selectedTime !== time)
      );
    } else {
      setSelectedArrivalTimes((prevTimes) => [...prevTimes, time]);
    }
  };

  const handleDepartureTimeChange = (value: string) => {
    if (selectedDepartureTimes.includes(value)) {
      setSelectedDepartureTimes(
        selectedDepartureTimes.filter((time) => time !== value)
      );
    } else {
      setSelectedDepartureTimes([...selectedDepartureTimes, value]);
    }
  };

  // Fastest flights
  const sortFlightsByDuration = (flights: any[]) => {
    const filteredFlights = flights.filter((flight) => {
      return (
        flight.OnwardFlight.NetFare !== 0 && flight.ReturnFlight.NetFare !== 0
      );
    });
    return filteredFlights
      .slice()
      .sort(
        (
          a: { OnwardFlight: { Duration: string } },
          b: { OnwardFlight: { Duration: string } }
        ) => {
          const parseDuration = (duration: string) => {
            const parts = duration.split(" ");
            const hours = parseInt(parts[0], 10);
            const minutes = parseInt(parts[1], 10);
            return hours * 60 + minutes;
          };

          const durationA = parseDuration(a?.OnwardFlight.Duration);
          const durationB = parseDuration(b?.OnwardFlight.Duration);

          return durationA - durationB;
        }
      );
  };

  const fastestFlights = filteredFlightsList?.filter(
    (flight: { OnwardFlight: { Duration: string } }) => {
      return flight?.OnwardFlight.Duration;
    }
  );

  const filteredFlightsByFare = filteredFlightsList.filter((flight) => {
    return (
      flight.OnwardFlight.NetFare !== 0 && flight.ReturnFlight.NetFare !== 0
    );
  });

  const sortedFlightsFastest = sortFlightsByDuration(fastestFlights);

  const sortedFlightByDeparture = filteredFlightsByFare
    ?.slice()
    .sort(
      (
        a: { OnwardFlight: { DepartureTime: string | number | Date } },
        b: { OnwardFlight: { DepartureTime: string | number | Date } }
      ) => {
        const departureTimeA: any = new Date(a.OnwardFlight.DepartureTime);
        const departureTimeB: any = new Date(b.OnwardFlight.DepartureTime);
        return departureTimeA - departureTimeB;
      }
    );

  const sortedFlightByArrival = filteredFlightsByFare
    ?.slice()
    .sort(
      (
        a: { OnwardFlight: { ArrivalTime: string | number | Date } },
        b: { OnwardFlight: { ArrivalTime: string | number | Date } }
      ) => {
        const arrivalTimeA: any = new Date(a.OnwardFlight.ArrivalTime);
        const arrivalTimeB: any = new Date(b.OnwardFlight.ArrivalTime);
        return arrivalTimeB - arrivalTimeA;
      }
    );

  const removeFilters = () => {
    setSelectedFlights([]);
    setSelectedArrivalAirport([]);
    setToggleFlightPrice(false);
    setRefundable(false);
    setOtherFare(false);
    setUpToFlights(0);
    setSelectedStops([]);
    setSelectedDepartureTimes([]);
    setSelectedArrivalTimes([]);
  };

  const handleSelectFlightCombination = (flight: any) => {
    const flightDetailsArray = [];

    flightDetailsArray.push({
      fare: flight.OnwardFlight.NetFare,
      orderId: 1,
      index: flight.OnwardFlight.Index,
      flightType:
        flight.OnwardFlight.flightType === "special" ? "special" : "rt",
    });

    if (flight.ReturnFlight) {
      flightDetailsArray.push({
        fare: flight.ReturnFlight.NetFare,
        orderId: 2,
        index: flight.ReturnFlight.Index,
        flightType:
          flight.OnwardFlight.flightType === "special" ? "special" : "rt",
      });
    }

    localStorage.setItem("bookingDetails", JSON.stringify(flightDetailsArray));

    window.open("/checkout-round-trip", "_blank");
  };

  const findSlab = (netFare: number) => {
    return slabsListData.flightSlabs.find(
      (slab: { minAmount: number; maxAmount: number }) =>
        netFare >= slab.minAmount && netFare <= slab.maxAmount
    );
  };

  const updateFlight = (flight: {
    OnwardFlight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      DiscountAmount: any;
    };
    ReturnFlight: {
      NetFare: any;
      CommissionRate: any;
      AddonRate: any;
      SellingPrice: any;
      MarkedPrice: any;
      Discount: any;
      DiscountAmount: any;
    };
  }) => {
    const netFare = flight.OnwardFlight.NetFare + flight.ReturnFlight.NetFare;
    const slab: any = findSlab(netFare);

    const markedPriceRate = netFare + netFare * (slab?.addonRate / 100);
    const sellingPriceRate = netFare + netFare * (slab?.commissionRate / 100);

    if (slab) {
      flight.OnwardFlight.CommissionRate = slab?.commissionRate;
      flight.OnwardFlight.AddonRate = slab?.addonRate;
      flight.OnwardFlight.MarkedPrice = Math.round(markedPriceRate);
      flight.OnwardFlight.SellingPrice = Math.round(sellingPriceRate + 1);
      flight.OnwardFlight.Discount = Math.round(
        ((flight.OnwardFlight.MarkedPrice - flight.OnwardFlight.SellingPrice) /
          flight.OnwardFlight.MarkedPrice) *
          100
      );
      flight.OnwardFlight.DiscountAmount = Math.round(
        flight.OnwardFlight.MarkedPrice - flight.OnwardFlight.SellingPrice
      );
    }

    return flight;
  };

  const updatedFlightsCheapest = sortedFlightsCheapest.map(
    (flight: {
      OnwardFlight: {
        NetFare: any;
        CommissionRate: any;
        AddonRate: any;
        SellingPrice: any;
        MarkedPrice: any;
        Discount: any;
        Duration: any;
        DiscountAmount: any;
      };
      ReturnFlight: {
        NetFare: any;
        CommissionRate: any;
        AddonRate: any;
        SellingPrice: any;
        MarkedPrice: any;
        Discount: any;
        Duration: any;
        DiscountAmount: any;
      };
    }) => updateFlight(flight)
  );

  useEffect(() => {
    const cheapestOnwardFlight = updatedFlightsCheapest[0]?.OnwardFlight
      ?.SellingPrice
      ? updatedFlightsCheapest[0].OnwardFlight
      : sortedFlightsCheapest[0]?.OnwardFlight;

    const formattedPrice = cheapestOnwardFlight?.SellingPrice
      ? cheapestOnwardFlight.SellingPrice.toLocaleString()
      : cheapestOnwardFlight?.NetFare.toLocaleString();

    setMinPriceRange(formattedPrice);
  }, [updatedFlightsCheapest, sortedFlightsCheapest]);

  const updatedFlightsFastest = sortedFlightsFastest.map(
    (flight: {
      OnwardFlight: {
        NetFare: any;
        CommissionRate: any;
        AddonRate: any;
        SellingPrice: any;
        MarkedPrice: any;
        Discount: any;
        Duration: any;
        DiscountAmount: any;
      };
      ReturnFlight: {
        NetFare: any;
        CommissionRate: any;
        AddonRate: any;
        SellingPrice: any;
        MarkedPrice: any;
        Discount: any;
        Duration: any;
        DiscountAmount: any;
      };
    }) => updateFlight(flight)
  );

  const updatedFlightsDeparture = sortedFlightByDeparture.map(
    (flight: {
      OnwardFlight: {
        NetFare: any;
        CommissionRate: any;
        AddonRate: any;
        SellingPrice: any;
        MarkedPrice: any;
        Discount: any;
        Duration: any;
        DiscountAmount: any;
      };
      ReturnFlight: {
        NetFare: any;
        CommissionRate: any;
        AddonRate: any;
        SellingPrice: any;
        MarkedPrice: any;
        Discount: any;
        Duration: any;
        DiscountAmount: any;
      };
    }) => updateFlight(flight)
  );

  const updatedFlightsArrival = sortedFlightByArrival.map(
    (flight: {
      OnwardFlight: {
        NetFare: any;
        CommissionRate: any;
        AddonRate: any;
        SellingPrice: any;
        MarkedPrice: any;
        Discount: any;
        Duration: any;
        DiscountAmount: any;
      };
      ReturnFlight: {
        NetFare: any;
        CommissionRate: any;
        AddonRate: any;
        SellingPrice: any;
        MarkedPrice: any;
        Discount: any;
        Duration: any;
        DiscountAmount: any;
      };
    }) => updateFlight(flight)
  );

  return (
    <AppLayout>
      <main className="container">
        {getCurrentSelectedTab === "hotelFlight" ? (
          <div className="tabbing-bar res_tabbing">
            <div className="tab-content">
              <HotelPlusFlight />
            </div>
          </div>
        ) : (
          <div className="tabbing-bar res_tabbing">
            <div className="tab-content">
              <SearchFlight />
            </div>
          </div>
        )}
        <>
          {flightListHistory.length !== 0 && (
            <div className="search-sticky">
              <div
                className="select-container res-hide-flight-tap"
                style={{ justifyContent: "flex-end" }}
              >
                <div
                  onClick={() => setFlightType("cheapest")}
                  className={
                    flightType === "cheapest"
                      ? "card card_cheapest_section card_active"
                      : "card card_cheapest_section"
                  }
                  style={{ width: "15rem" }}
                >
                  <div className="card-body px-2 py-1">
                    <h5 className="card-title card_title mb-0">Cheapest</h5>
                    <span className="card_text">
                      ₹{" "}
                      {updatedFlightsCheapest[0]?.OnwardFlight?.SellingPrice
                        ? updatedFlightsCheapest[0]?.OnwardFlight?.SellingPrice.toLocaleString()
                        : (
                            sortedFlightsCheapest[0]?.OnwardFlight.NetFare +
                            sortedFlightsCheapest[0]?.ReturnFlight.NetFare
                          ).toLocaleString()}{" "}
                      ・ Duration:{" "}
                      {sortedFlightsCheapest[0]?.OnwardFlight?.Duration}
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => setFlightType("fastest")}
                  className={
                    flightType === "fastest"
                      ? "card card_cheapest_section card_active"
                      : "card card_cheapest_section"
                  }
                  style={{ width: "15rem" }}
                >
                  <div className="card-body px-2 py-1">
                    <h5 className="card-title card_title mb-0">Fastest</h5>
                    <span className="card_text">
                      ₹{" "}
                      {updatedFlightsFastest[0]?.OnwardFlight?.SellingPrice
                        ? updatedFlightsFastest[0]?.OnwardFlight?.SellingPrice.toLocaleString()
                        : // : sortedFlightsCheapest[0]?.OnwardFlight.NetFare.toLocaleString()}{" "}
                          (
                            sortedFlightsFastest[0]?.OnwardFlight.NetFare +
                            sortedFlightsFastest[0]?.ReturnFlight.NetFare
                          ).toLocaleString()}{" "}
                      ・ Duration:{" "}
                      {sortedFlightsFastest[0]?.OnwardFlight?.Duration}
                    </span>
                  </div>
                </div>

                <div
                  onClick={() => setFlightType("departure")}
                  className={
                    flightType === "departure"
                      ? "card card_cheapest_section card_active"
                      : "card card_cheapest_section"
                  }
                  style={{ width: "15rem" }}
                >
                  <div className="card-body px-2 py-1">
                    <h5 className="card-title card_title mb-0">Departure</h5>

                    <span className="card_text">
                      ₹{" "}
                      {updatedFlightsDeparture[0]?.OnwardFlight?.SellingPrice
                        ? updatedFlightsDeparture[0]?.OnwardFlight?.SellingPrice.toLocaleString()
                        : // : sortedFlightsCheapest[0]?.OnwardFlight.NetFare.toLocaleString()}{" "}
                          (
                            sortedFlightByDeparture[0]?.OnwardFlight.NetFare +
                            sortedFlightByDeparture[0]?.ReturnFlight.NetFare
                          ).toLocaleString()}{" "}
                      ・ Duration:{" "}
                      {sortedFlightByDeparture[0]?.OnwardFlight?.Duration}
                      {/* <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ fontSize: "large" }}
                      /> */}
                    </span>
                  </div>
                </div>

                <div
                  onClick={() => setFlightType("arrival")}
                  className={
                    flightType === "arrival"
                      ? "card card_cheapest_section card_active"
                      : "card card_cheapest_section"
                  }
                  style={{ width: "15rem" }}
                >
                  <div className="card-body px-2 py-1">
                    <h5 className="card-title card_title mb-0">Arrival</h5>
                    <span className="card_text">
                      ₹{" "}
                      {updatedFlightsArrival[0]?.OnwardFlight?.SellingPrice
                        ? updatedFlightsArrival[0]?.OnwardFlight?.SellingPrice.toLocaleString()
                        : // : sortedFlightsCheapest[0]?.OnwardFlight.NetFare.toLocaleString()}{" "}
                          (
                            sortedFlightByArrival[0]?.OnwardFlight.NetFare +
                            sortedFlightByArrival[0]?.OnwardFlight.NetFare
                          ).toLocaleString()}{" "}
                      ・ Duration:{" "}
                      {sortedFlightByArrival[0]?.OnwardFlight?.Duration}
                    </span>
                  </div>
                </div>
              </div>
              <div className="res-show-flight-tap">
                <div className="d-flex justify-content-between align-content-center">
                  <div className="left-filter">
                    <h5
                      className="h3-bold mb-0"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <span>
                        <LazyImage
                          className="img-fluid"
                          src={arrowImage}
                          alt=""
                        />{" "}
                      </span>
                      Sort
                    </h5>
                  </div>
                  <div className="right-filter">
                    <h5
                      className="h3-bold mb-0"
                      data-bs-toggle="modal"
                      data-bs-target="#flight-exampleModal_flight_search"
                    >
                      <span>
                        <LazyImage
                          className="img-fluid"
                          src={filterImage}
                          alt=""
                        />{" "}
                      </span>
                      Filter
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="modal modal-bottom fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal_bottom">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="h3 bold">Sort</h3>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={flightType === "cheapest"}
                      onChange={() => setFlightType("cheapest")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Cheapest
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={flightType === "fastest"}
                      onChange={() => setFlightType("fastest")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      Fastest
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault4"
                      checked={flightType === "departure"}
                      onChange={() => setFlightType("departure")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault4"
                    >
                      Departure
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      checked={flightType === "arrival"}
                      onChange={() => setFlightType("arrival")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault3"
                    >
                      Arrival
                    </label>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-login search_record"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-login search_record"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {flightIndication && flightListHistory.length === 0 ? (
              <div style={{ textAlign: "center", fontSize: "27px" }}>
                No Data Found.
              </div>
            ) : (
              <>
                {/* Left side filter  */}
                <SidebarFilter
                  priceRange={priceRange}
                  selectedFlights={selectedFlights}
                  selectedArrivalAirport={selectedArrivalAirport}
                  setPriceRange={setPriceRange}
                  handleArrivalTimeChange={handleArrivalTimeChange}
                  selectedArrivalTimes={selectedArrivalTimes}
                  setSelectedFlights={setSelectedFlights}
                  setSelectedArrivalAirport={setSelectedArrivalAirport}
                  uniqueConnectingAirports={uniqueConnectingAirports}
                  getHightestPrice={getHightestPrice as number}
                  setToggleFlightPrice={setToggleFlightPrice}
                  refundable={refundable}
                  setRefundable={setRefundable}
                  includeOtherFare={true}
                  otherFare={otherFare}
                  setOtherFare={setOtherFare}
                  handleNoOfStopsChange={handleNoOfStopsChange}
                  selectedStops={selectedStops}
                  handleDepartureTimeChange={handleDepartureTimeChange}
                  selectedDepartureTimes={selectedDepartureTimes}
                  flightList={getIndexFlightSearch}
                  upToFlights={upToFlights}
                  setUpToFlights={setUpToFlights}
                  minPriceRange={minPriceRange}
                  filteredFlightsList={filteredFlightsList}
                  index={0}
                />

                {/* ---Flight list----  */}
                <div className="col-sm-9">
                  <div>
                    {/* Cheapest Flights */}
                    {flightType === "cheapest" &&
                    flightIndication &&
                    filteredFlightsList.length === 0 ? (
                      <h1>No Data Found </h1>
                    ) : (
                      <>
                        {flightType === "cheapest" && (
                          <>
                            <RoundTripFlightsList
                              key={Math.random()}
                              flightList={filteredFlightsList}
                              removeFilters={removeFilters}
                              handleSelect={handleSelectFlightCombination}
                              slabsListData={slabsListData}
                            />
                          </>
                        )}
                      </>
                    )}

                    {/* Fastest Flights */}
                    {flightType === "fastest" &&
                    flightIndication &&
                    filteredFlightsList.length === 0 ? (
                      <h1>No Data Found</h1>
                    ) : (
                      <>
                        {flightType === "fastest" && (
                          <>
                            <RoundTripFastestFlightsList
                              flightList={filteredFlightsList}
                              removeFilters={removeFilters}
                              handleSelect={handleSelectFlightCombination}
                              slabsListData={slabsListData}
                            />
                          </>
                        )}
                      </>
                    )}

                    {flightType === "departure" &&
                    flightIndication &&
                    filteredFlightsList.length === 0 ? (
                      <h1>No Data Found</h1>
                    ) : (
                      <>
                        {flightType === "departure" && (
                          <>
                            <RoundTripFlightsList
                              key={Math.random()}
                              flightList={filteredFlightsList}
                              removeFilters={removeFilters}
                              handleSelect={handleSelectFlightCombination}
                              tag="departure"
                              slabsListData={slabsListData}
                            />
                          </>
                        )}
                      </>
                    )}

                    {flightType === "arrival" &&
                    flightIndication &&
                    filteredFlightsList.length === 0 ? (
                      <h1>No Data Found</h1>
                    ) : (
                      <>
                        {flightType === "arrival" && (
                          <>
                            <RoundTripFlightsList
                              key={Math.random()}
                              flightList={filteredFlightsList}
                              removeFilters={removeFilters}
                              handleSelect={handleSelectFlightCombination}
                              tag="arrival"
                              slabsListData={slabsListData}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </main>

      {flightIndication && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 17%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <span style={{ fontSize: "26px", color: "red" }}>
            {filteredFlightsList.length + " results"}
          </span>
        </div>
      )}

      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
