import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import { TalkToAgents } from "../../components/TalkToAgent";
import { TimeRemaining } from "../../components/forms/flight/TimeRemaining";
import airPlainTilt from "../../assets/images/AirplaneTilt.png";
// import importantTag from "../../assets/images/important.png";
import { AddonsSection } from "../../components/forms/flight/AddonsSection";
import infoImage from "../../assets/images/info.png";
import carretDown from "../../assets/images/CaretDown.png";
import envelopImg from "../../assets/images/EnvelopeSimple.png";
import mangifyGlass from "../../assets/images/MagnifyingGlass.svg";
import leftArrow from "../../assets/images/icons8-left-30.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  AddonsProvider,
  useAddons,
} from "../../components/forms/flight/addonccontext";

import {
  checkChangedFare,
  flightItinerary,
  flightPayment,
  getSSRforBooking,
  getSeatLayout,
  getSmartPriceForBooking,
  getSmartPriceForToken,
  saveFlightGuest,
} from "../../api/FlightAPI";
import axios from "axios";
import moment from "moment";
import { useFlightLoader } from "../../components/forms/shared/useFlightLoader";
import AirplaneLoader from "../../components/loaders/AirplaneLoader";
import { CheckoutFormPassenger } from "./CheckoutFormPassenger";
import { toast } from "react-toastify";
import { emailRegex } from "../../utils/validation";
import { getUserProfile } from "../../api/UserProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { MultiCitySideBarCheckout } from "../../components/forms/flight/SidebarMulticityCheckout";
import { slabsList } from "../../api/AdminAPI";
import { host } from "../../api/endpoints";

const initialAdultObject = {
  id: 0,
  type: "adult",
  title: "Title",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  passportNumber: "",
  passportCountry: "",
  passportExpiry: "",
  nationality: "",
};
const initialChildObject = {
  id: 0,
  type: "child",
  title: "Title",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  passportNumber: "",
  passportCountry: "",
  passportExpiry: "",
  nationality: "",
};
const initialInfantObject = {
  id: 0,
  type: "infant",
  title: "Title",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  passportNumber: "",
  passportCountry: "",
  passportExpiry: "",
  nationality: "",
};

export const CheckoutMultiCity = () => {
  const flightSearchDetailsJSON = localStorage.getItem(
    "userMultiFlightSearchData"
  );
  const flightSearchDetails = JSON.parse(String(flightSearchDetailsJSON));

  const [catchErrors, setCatchErrors] = useState(false);

  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [checkGst, setCheckGst] = useState(false);
  const [errorForForms, setErrorForForms] = useState(false);
  const [slabsListData, setSlabsListData] = useState<any>([]);
  const [sellingPrice, setSellingPrice] = useState(0);

  const [flightDetailsObject, setFlightDetailsObject] = useState<any>();
  const [countries, setCountries] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [userData, setUserData] = useState<any>({});
  const [storeSSR, setStoreSSR] = useState<any>([]);

  const [paidSSRList, setPaidSSRList] = useState<any>([]);
  const [seatpaidSSRList, setSeatPaidSSRList] = useState<any>([]);
  const [addonDetails, setAddonDetails] = useState<any>([]);
  const [addonDetailsChild, setAddonDetailsChild] = useState<any>([]);
  const [addonDetailsInfant, setAddonDetailsInfant] = useState<any>([]);

  const [mealAddonDetailsChild, setMealAddonDetailsChild] = useState<any>([]);
  const [mealAddonDetailsInfant, setMealAddonDetailsInfant] = useState<any>([]);
  const [seatAddonDetailsChild, setSeatAddonDetailsChild] = useState<any>([]);
  const [seatAddonDetailsInfant, setSeatAddonDetailsInfant] = useState<any>([]);
  const [sportBaggageDetailsChild, setSportBaggageDetailsChild] = useState<any>(
    []
  );
  const [sportBaggageDetailsInfant, setSportBaggageDetailsInfant] =
    useState<any>([]);
  const [priorityBaggageDetailsChild, setPriorityBaggageDetailsChild] =
    useState<any>([]);
  const [priorityBaggageDetailsInfant, setPriorityBaggageDetailsInfant] =
    useState<any>([]);

  const [mealAddonDetails, setMealAddonDetails] = useState<any>([]);
  const [seatAddonDetails, setSeatAddonDetails] = useState<any>([]);
  const [sportBaggageDetails, setSportBaggageDetails] = useState<any>([]);
  const [priorityBaggageDetails, setPriorityBaggageDetails] = useState<any>([]);

  const [storeCheckDomestic, setStoreCheckDomestic] = useState<any>({});
  const [searchText, setSearchText] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [phoneCodeValue, setPhoneCodeValue] = useState("91");

  const [smartPriceTUI, setSmartPriceTUI] = useState<any[]>([]);
  const [userValues, setUserValues] = useState<{ id: string }>({ id: "" });

  const [submitClicked, setSubmitClicked] = useState(false);
  const [soldOutCheck, setSoldOutCheck] = useState(false);

  const [guestLoginIndication, setGuestLoginIndication] = useState(false);

  const [flightResponseArray, setFlightResponseArray] = useState<any[]>([]);

  const bookingDetailJSONCheck = localStorage.getItem("bookingDetails");
  const bookingDetailsCheck = JSON.parse(String(bookingDetailJSONCheck));

  const [registrationNumber, setRegistrationNumber] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [emailGST, setEmailGST] = useState<string>("");
  const [mobileGST, setMobileGST] = useState<string>("");

  const navigate = useNavigate();
  const { isFlightLoading, setIsFlightLoading } = useFlightLoader();

  useEffect(() => {
    setIsFlightLoading(true);
    const bookingDetailJSON = localStorage.getItem("bookingDetails");

    if (bookingDetailJSON) {
      try {
        const bookingDetails = JSON.parse(String(bookingDetailJSON));
        const tuiArrayJSON = localStorage.getItem("flightJourneyMulti");
        let tuiArray = tuiArrayJSON
          ? JSON.parse(tuiArrayJSON).map((item: { TUI: any }) => item.TUI)
          : [];

        const handleBooking = async () => {
          const promises: any[] = [];
          const responseArray: any = [];

          for (const detail of bookingDetails) {
            const tui = tuiArray?.shift();

            if (tui) {
              try {
                await getSmartPriceForToken({
                  //  ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
                  ClientID: "API19201",
                  Mode: "SS",
                  Options: "A",
                  Source: "SF",
                  TripType: "DM",
                  Trips: [
                    {
                      Amount: detail.fare,
                      Index: detail.index,
                      ChannelCode: null,
                      OrderID: 1,
                      TUI: String(tui),
                    },
                  ],
                })
                  .then((res) => {
                    setTimeout(() => {
                      getSmartPriceForBooking({
                        //  ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
                        ClientID: "API19201",
                        TUI: res.data.TUI,
                      })
                        .then((res) => {
                          if (res.data.Trips !== null) {
                            setFlightDetailsObject(res);

                            responseArray.push(res?.data);

                            setTimeout(() => {
                              checkChangedFare({
                                TUI: res.data.TUI,
                                //ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
                                ClientID: "API19201",
                              })
                                .then((res) => {
                                  setIsFlightLoading(false);
                                  res?.data?.TravellerCheckList !== null &&
                                    setStoreCheckDomestic(
                                      res?.data?.TravellerCheckList[0]
                                    );
                                })
                                .catch((err) => {
                                  setIsFlightLoading(false);
                                  setCatchErrors(true);
                                });

                              setTimeout(() => {
                                getSSRforBooking({
                                  PaidSSR: false,
                                  // ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
                                  ClientID: "API19201",
                                  Source: "LV",
                                  Trips: [
                                    {
                                      TUI: res.data.TUI,
                                      Amount: 0,
                                      OrderID: 1,
                                      Index: "",
                                    },
                                  ],
                                })
                                  .then((response) => {
                                    response?.data?.Trips !== null &&
                                      setStoreSSR(
                                        response?.data?.Trips[0]?.Journey[0]
                                          ?.Segments[0]?.SSR
                                      );
                                  })
                                  .catch((error) => setCatchErrors(true));
                              }, 4000);

                              // new code here for addons
                              // Additional calls after handleBooking completes
                              setTimeout(() => {
                                getSSRforBooking({
                                  PaidSSR: true,
                                  // ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
                                  ClientID: "API19201",
                                  Source: "LV",
                                  Trips: [
                                    {
                                      TUI: res.data.TUI,
                                      Amount: 0,
                                      OrderID: 1,
                                      Index: "",
                                    },
                                  ],
                                })
                                  .then((response) => {
                                    if (
                                      response?.data?.Trips[0]?.Journey[0]
                                        ?.Segments.length > 0
                                    ) {
                                      setPaidSSRList(response?.data?.Trips);
                                    }
                                  })
                                  .catch((error) =>
                                    console.log(error, "ssr error")
                                  );
                              }, 4000);

                              getSeatLayout({
                                // ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
                                ClientID: "API19201",
                                Source: "LV",
                                Trips: [
                                  {
                                    TUI: res.data.TUI,
                                    OrderID: 1,
                                    Index: "",
                                  },
                                ],
                              }).then((res) => {
                                if (
                                  res?.data?.Trips[0]?.Journey[0]?.Segments
                                    .length > 0
                                ) {
                                  setSeatPaidSSRList(res?.data?.Trips);
                                }
                                console.log(
                                  res,
                                  "--- get seat laoyout res seat"
                                );
                              });
                            }, 3000);
                          } else {
                            setIsFlightLoading(false);
                            setCatchErrors(true);
                          }
                        })
                        .catch((error) => {
                          setCatchErrors(true);
                          setFlightDetailsObject(null);
                        });
                    }, 2000);
                    localStorage.setItem("smartTUI", res.data.TUI);

                    setSmartPriceTUI((prevTUIs) => [...prevTUIs, res.data.TUI]);

                    promises.push(res.data.TUI);
                  })
                  .catch((err) => setCatchErrors(true));
              } catch (error) {
                setCatchErrors(true);
                console.error("Error while processing booking detail:", error);
              }
            }
          }

          await Promise.all(promises);

          setFlightResponseArray(responseArray);
        };

        handleBooking();
      } catch (error) {
        setCatchErrors(true);
        console.error("Error parsing JSON:", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // new code for adddons

  const handleRegistrationNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRegistrationNumber(event.target.value);
  };

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailGST(event.target.value);
  };

  const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMobileGST(event.target.value);
  };

  const {
    addonDetailsData,
    setAddonDetailsData,
    mealAddonDetailsData,
    setMealAddonDetailsData,
    seatAddonDetailsData,
    setSeatAddonDetailsData,
    finalAmount,
    setFinalAmount,
    totalValue,
    setTotalValue,
    totalAmount,
    setTotalAmount,
    totalMealValue,
    setTotalMealValue,
    totalSeatValue,
    setTotalSeatValue,
    totalSportBaggageValue,
    setTotalSportBaggageValue,
    totalPriorityBaggageValue,
    setTotalPriorityBaggageValue,
    totalSSRAmount,
    setTotalSSRAmount,
  } = useAddons();

  // Function to extract values from an array
  // const extractValues = (dataArray: any[]) => {
  //   if (dataArray && dataArray.length > 0) {
  //     const uniqueIds = new Set<number>();
  //     const latestAddonDetailsArray: any[] = [];

  //     for (let i = dataArray.length - 1; i >= 0; i--) {
  //       const { PaxID, charge, Description, SeatNumber, Fare, ...rest } = dataArray[i];

  //       if (!uniqueIds.has(PaxID)) {
  //         uniqueIds.add(PaxID);

  //         if (Object.values(rest).some(value => value != null)) {
  //           latestAddonDetailsArray.unshift({ PaxID, ...rest });
  //         }
  //       }
  //     }
  //     return latestAddonDetailsArray;
  //   } else {
  //     return [];
  //   }
  // };
  const extractValues = (obj: any) => {
    const resultArray: any[] = [];
    Object.keys(obj).forEach((key) => {
      obj[key].forEach((item: any) => {
        const {
          Description,
          SeatNumber,
          Fare,
          charge,
          adultId,
          flightIndex,
          ...rest
        } = item;
        resultArray.push({ PaxID: key, ...rest });
      });
    });
    return resultArray;
  };

  // Sample arrays
  const DataaddonDetails = addonDetails;
  const DataaddonDetailsChild = addonDetailsChild;
  const DataaddonDetailsInfant = addonDetailsInfant;
  const DatamealAddonDetails = mealAddonDetails;
  const DatamealAddonDetailsChild = mealAddonDetailsChild;
  const DatamealAddonDetailsInfant = mealAddonDetailsInfant;
  const DataseatAddonDetails = seatAddonDetails;
  const DataseatAddonDetailsChild = seatAddonDetailsChild;
  const DataseatAddonDetailsInfant = seatAddonDetailsInfant;
  const DatasportBaggageDetails = sportBaggageDetails;
  const DatasportBaggageDetailsChild = sportBaggageDetailsChild;
  const DatasportBaggageDetailsInfant = sportBaggageDetailsInfant;
  const DatapriorityBaggageDetails = priorityBaggageDetails;
  const DatapriorityBaggageDetailsChild = priorityBaggageDetailsChild;
  const DatapriorityBaggageDetailsInfant = priorityBaggageDetailsInfant;

  // Call the extractValues function for each array
  const addonDetailsArray = extractValues(DataaddonDetails);
  const addonDetailsChildArray = extractValues(DataaddonDetailsChild);
  const addonDetailsInfantArray = extractValues(DataaddonDetailsInfant);
  const mealAddonDetailsArray = extractValues(DatamealAddonDetails);
  const mealAddonDetailsChildArray = extractValues(DatamealAddonDetailsChild);
  const mealAddonDetailsInfantArray = extractValues(DatamealAddonDetailsInfant);

  const seatAddonDetailsArray = extractValues(DataseatAddonDetails);
  const seatAddonDetailsChildArray = extractValues(DataseatAddonDetailsChild);
  const seatAddonDetailsInfantArray = extractValues(DataseatAddonDetailsInfant);

  const sportBaggageDetailsArray = extractValues(DatasportBaggageDetails);
  const sportBaggageDetailsChildArray = extractValues(
    DatasportBaggageDetailsChild
  );
  const sportBaggageDetailsInfantArray = extractValues(
    DatasportBaggageDetailsInfant
  );

  const priorityBaggageDetailsArray = extractValues(DatapriorityBaggageDetails);
  const priorityBaggageDetailsChildArray = extractValues(
    DatapriorityBaggageDetailsChild
  );
  const priorityBaggageDetailsInfantArray = extractValues(
    DatapriorityBaggageDetailsInfant
  );

  const ssrDetails = [
    ...addonDetailsArray,
    ...addonDetailsChildArray,
    ...addonDetailsInfantArray,
    ...mealAddonDetailsArray,
    ...mealAddonDetailsChildArray,
    ...mealAddonDetailsInfantArray,
    ...seatAddonDetailsArray,
    ...seatAddonDetailsChildArray,
    ...seatAddonDetailsInfantArray,
    ...sportBaggageDetailsArray,
    ...sportBaggageDetailsChildArray,
    ...sportBaggageDetailsInfantArray,
    ...priorityBaggageDetailsArray,
    ...priorityBaggageDetailsChildArray,
    ...priorityBaggageDetailsInfantArray,
  ];

  const [adultsArray, setAdultsArray] = useState([initialAdultObject]);
  const [childrensArray, setchildrensArray] = useState([initialChildObject]);
  const [infantsArray, setInfantsArray] = useState([initialInfantObject]);

  useEffect(() => {
    slabsList()
      .then((res) => {
        setSlabsListData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const getResponseCountryCode = () => {
      axios.get(`${host}/api/services/country-list`).then((response) => {
        setCountries(response.data.data);
      });
    };
    getResponseCountryCode();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowModal(true);
    }, 15 * 60 * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const getUserFlightDataJSON = localStorage.getItem(
      "userMultiFlightSearchData"
    );
    const parsedDataUser = JSON.parse(String(getUserFlightDataJSON));
    setUserData(parsedDataUser);
  }, []);

  useEffect(() => {
    const newAdultsArray = Array.from({ length: userData.ADT }, () => ({
      ...initialAdultObject,
      id: Math.random(),
    }));
    setAdultsArray(newAdultsArray);

    const newChildrensArray = Array.from({ length: userData.CHD }, () => ({
      ...initialAdultObject,
      id: Math.random(),
      type: "child",
      title: "Title",
    }));
    setchildrensArray(newChildrensArray);

    const newInfantsArray = Array.from({ length: userData.INF }, () => ({
      ...initialAdultObject,
      id: Math.random(),
      type: "infant",
      title: "Title",
    }));
    setInfantsArray(newInfantsArray);
  }, [userData.ADT, userData.CHD, userData.INF]);

  const checkConnectingFlights =
    flightDetailsObject?.data?.Trips !== null &&
    flightDetailsObject?.data?.Trips?.[0]?.Journey?.[0]?.Segments;

  if (checkConnectingFlights !== undefined) {
    console.log(
      flightDetailsObject,
      flightDetailsObject?.data?.Trips !== null &&
        flightDetailsObject?.data?.Trips?.[0]?.Journey?.[0]?.Segments,
      "--obj"
    );
  } else {
    console.log("undefined");
  }

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        setUserValues({ id: response.data._id });
      })
      .catch((err) => {
        console.log("profile error", err);
      });
  }, []);

  const checkTitle = (obj: any) => {
    return obj.title === "Title";
  };

  const onConfirmHandler = () => {
    const hasEmptyValues = (obj: any) => {
      for (const key in obj) {
        if (
          storeCheckDomestic?.PassportNo !== 1 &&
          (key === "passportNumber" ||
            key === "passportCountry" ||
            key === "passportExpiry")
        ) {
          continue;
        }

        if (storeCheckDomestic?.DOB !== 1) {
          continue;
        }

        if (storeCheckDomestic?.Nationality !== 1 && key === "nationality") {
          continue;
        }

        if (obj[key] === "") {
          return true;
        }
      }
      return false;
    };

    if (
      adultsArray.some(hasEmptyValues) ||
      childrensArray.some(hasEmptyValues) ||
      infantsArray.some(hasEmptyValues) ||
      emailValue === "" ||
      phoneValue === ""
    ) {
      emailValue === "" && setEmailError(true);
      phoneValue === "" && setMobileError(true);
      toast.error("Please Fill All required User Information", {
        toastId: "errorToastRegister2",
      });
    } else if (
      adultsArray.some(checkTitle) ||
      childrensArray.some(checkTitle) ||
      infantsArray.some(checkTitle)
    ) {
      toast.error("Please Enter Valid Title", {
        toastId: "errorToastRegister3",
      });
    } else {
      !errorForForms && setToggleEditModal(true);
      setMobileError(false);
      setEmailError(false);
    }
    setSubmitClicked(true);
  };

  const getConnectingObj =
    checkConnectingFlights && checkConnectingFlights[0]?.Flight;

  function calculateTimeDifference(startTime: any, endTime: any) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const timeDifferenceInMilliseconds = Math.abs(
      endDate.getTime() - startDate.getTime()
    );
    const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${String(hours).padStart(2, "0")}h ${String(minutes).padStart(
      2,
      "0"
    )}min`;
  }

  const updateAdultAtIndex = (
    index: number,
    newData: {
      id: number;
      type: string;
      title: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      passportNumber: string;
      passportCountry: string;
      passportExpiry: string;
      nationality: string;
    }
  ) => {
    setAdultsArray((prevAdultsArray) => {
      // Create a new array with the updated data
      const updatedArray = [...prevAdultsArray];
      updatedArray[index] = newData;
      return updatedArray;
    });
  };

  const updateChildrenAtIndex = (
    index: number,
    newData: {
      id: number;
      type: string;
      title: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      passportNumber: string;
      passportCountry: string;
      passportExpiry: string;
      nationality: string;
    }
  ) => {
    setchildrensArray((prevChildrensArray) => {
      // Create a new array with the updated data
      const updatedArray = [...prevChildrensArray];
      updatedArray[index] = newData;
      return updatedArray;
    });
  };

  const updateInfantsAtIndex = (
    index: number,
    newData: {
      id: number;
      type: string;
      title: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      passportNumber: string;
      passportCountry: string;
      passportExpiry: string;
      nationality: string;
    }
  ) => {
    setInfantsArray((prevInfantsArray) => {
      // Create a new array with the updated data
      const updatedArray = [...prevInfantsArray];
      updatedArray[index] = newData;
      return updatedArray;
    });
  };

  const combineTravelerArr = [
    ...adultsArray,
    ...childrensArray,
    ...infantsArray,
  ];

  function calculateAge(birthdate: string, travelDate: string) {
    const birthDate = new Date(birthdate);
    const travelDateObj = new Date(travelDate);

    const ageDiff = travelDateObj.getFullYear() - birthDate.getFullYear();

    if (
      travelDateObj.getMonth() < birthDate.getMonth() ||
      (travelDateObj.getMonth() === birthDate.getMonth() &&
        travelDateObj.getDate() < birthDate.getDate())
    ) {
      return ageDiff - 1;
    }

    return ageDiff;
  }

  const combinedArr = combineTravelerArr?.map((info, index) => ({
    ID: Number(index + 1),
    PaxID: "",
    Operation: "0",
    Title: info.title,
    FName: info.firstName.toUpperCase(),
    LName: info.lastName.toUpperCase(),
    Email: "",
    PMobileNo: "",
    Age: calculateAge(
      moment(info.dateOfBirth).format("YYYY-MM-DD"),
      flightSearchDetails.OnwardDate
    ),
    //  DOB: moment(info.dateOfBirth).format("YYYY-MM-DD"),
    DOB:
      info.dateOfBirth !== ""
        ? moment(info.dateOfBirth).format("YYYY-MM-DD")
        : "",
    Country: "",
    Gender: info.title === "Mr" ? "M" : info.title === "Mstr" ? "M" : "F",
    PTC: info.type === "adult" ? "ADT" : info.type === "child" ? "CHD" : "INF",
    Nationality: info.nationality ? info.nationality : "",
    PassportNo: info.passportNumber,
    PancardNo: "",
    PLI: "",
    PDOI: "",
    PDOE: "",
    VisaType: "VISITING VISA",
    EmigrationCheck: false,
    isOptionSelected: false,
    ApproverManagers: {
      Managers: [],
      Type: "",
    },
    DocumentType: "",
  }));

  const submitItinerary = () => {
    let totalGrossAmount = 0;
    const createITernTUIArray: { TUI: any; transactionId: any }[] = [];
    flightResponseArray.map((flightResponse, index) => {
      const firstAdultsArrValue = adultsArray[0];
      const tui = smartPriceTUI[index];
      return flightItinerary({
        TUI: String(tui),
        ServiceEnquiry: "",
        ContactInfo: {
          Title: firstAdultsArrValue.title,
          FName: firstAdultsArrValue.firstName.toUpperCase(),
          LName: firstAdultsArrValue.lastName.toUpperCase(),
          Mobile: phoneValue,
          DestMob: phoneValue,
          Phone: "",
          Email: emailValue,
          Language: "",
          Address: "MRRA 4  EDAPPALLY  Edappally , EDAPPALLY , Edappally",
          City: "Cochin",
          State: "Kerala",
          CountryCode: "IN",
          MobileCountryCode: `+${phoneCodeValue}`,
          DestMobCountryCode: "+91",
          PIN: "",
          GSTCompanyName: companyName,
          GSTTIN: registrationNumber,
          GstMobile: mobileGST,
          GSTEmail: emailGST,
          UpdateProfile: false,
          IsGuest: false,
          SaveGST: false,
        },
        DestinationContactInfo: {
          Address1: "",
          Address2: "",
          City: "",
          Mobile: "",
          Phone: "",
          Email: "",
          CountryCode: "",
          MobileCountryCode: `+${phoneCodeValue}`,
          State: "",
          PIN: "",
        },
        Travellers: combinedArr,
        PLP: [],
        // SSR: null,
        SSR: ssrDetails,
        CrossSell: [],
        CrossSellAmount: 0,
        EnableFareMasking: false,
        SSRAmount: +totalSSRAmount,
        //  ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
        ClientID: "API19201",
        DeviceID: "",
        AppVersion: "",
        AgentTourCode: "",
        NetAmount: flightResponse?.NetAmount,
        BRulesAccepted: "",
      })
        .then((res) => {
          totalGrossAmount += res?.data.NetAmount;

          const tuiTransaction = {
            TUI: res?.data.TUI,
            transactionId: res?.data.TransactionID,
          };

          createITernTUIArray.push(tuiTransaction);

          if (index === flightResponseArray.length - 1) {
            setIsFlightLoading(true);

            if (userValues.id === "") {
              saveFlightGuest({
                email: emailValue,
                mobileNumber: phoneValue,
                countryCode: phoneCodeValue,
              })
                .then((response) => {
                  setUserValues({ id: response.data._id });
                  flightPayment({
                    userId: response.data._id,
                    txnId: String(res.data.TransactionID),
                    amount:
                      sellingPrice && sellingPrice !== 0
                        ? String(sellingPrice)
                        : String(totalGrossAmount + ".00"),
                    // amount:
                    //   (
                    //     (sellingPrice && sellingPrice !== 0 ? Number(sellingPrice) :
                    //       Number(res.data.NetAmount)) +
                    //     Number(totalSSRAmount)
                    //   ).toFixed(2),
                    productInfo: "flight",
                    firstName: firstAdultsArrValue.firstName,
                    lastName: firstAdultsArrValue.lastName,
                    email: emailValue,
                    phone: phoneValue,
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    country: "",
                    zipcode: "",
                    Pg: "",
                    udf5: "Maashree",
                  })
                    .then((responses) => {
                      const paymentObjString =
                        localStorage.getItem("paymentObj");
                      let paymentObj;

                      if (!paymentObjString) {
                        paymentObj = {};
                      } else {
                        paymentObj = JSON.parse(paymentObjString);
                      }

                      paymentObj.userId = response.data._id;
                      localStorage.setItem(
                        "paymentObj",
                        JSON.stringify(paymentObj)
                      );
                      setIsFlightLoading(false);
                      window.open(responses.data, "_self");
                    })
                    .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
            }
            if (userValues.id !== "") {
              flightPayment({
                userId: String(userValues?.id),
                txnId: String(res.data.TransactionID),
                // amount:
                //   sellingPrice && sellingPrice !== 0
                //     ? String(sellingPrice)
                //     : String(totalGrossAmount + ".00"),
                amount: (
                  (sellingPrice && sellingPrice !== 0
                    ? Number(sellingPrice)
                    : Number(res.data.NetAmount)) + Number(totalSSRAmount)
                ).toFixed(2),
                productInfo: "flight",
                firstName: firstAdultsArrValue.firstName,
                lastName: firstAdultsArrValue.lastName,
                email: emailValue,
                phone: phoneValue,
                address1: "",
                address2: "",
                city: "",
                state: "",
                country: "",
                zipcode: "",
                Pg: "",
                udf5: "Maashree",
              })
                .then((responses) => {
                  const paymentObjString = localStorage.getItem("paymentObj");
                  let paymentObj;

                  if (!paymentObjString) {
                    paymentObj = {};
                  } else {
                    paymentObj = JSON.parse(paymentObjString);
                  }

                  paymentObj.userId = String(userValues?.id);
                  localStorage.setItem(
                    "paymentObj",
                    JSON.stringify(paymentObj)
                  );
                  setIsFlightLoading(false);
                  window.open(responses.data, "_self");
                })
                .catch((err) => console.log(err));
            }
          }
          localStorage.setItem(
            "createITernTUI",
            JSON.stringify(createITernTUIArray)
          );
        })
        .catch((err) => {
          if (
            err.response.data.message ===
            "The requested class of service is sold out., Provider:6E"
          ) {
            setSoldOutCheck(true);
          } else {
            toast.error(err.response.data.message, {
              toastId: "confirmErrorFlight",
            });
          }
        });
    });
  };

  flightResponseArray.sort((a: any, b: any) => {
    const dateA: any = new Date(a.OnwardDate);
    const dateB: any = new Date(b.OnwardDate);
    return dateA - dateB;
  });

  const testObj = flightResponseArray;
  console.log("flightResponseArray:", flightResponseArray);
  const testdata = flightResponseArray?.[0]?.Trips?.[0]?.Journey?.[0]?.Segments;
  if (testdata === undefined) {
    console.log(
      "testdata is undefined. Please verify the structure of flightResponseArray."
    );
  } else {
    const newObjectdaa = {
      testdata: testdata.map((segment: any) => ({
        flight: segment.Flight,
        fares: segment.Fares,
      })),
    };

    const flights = flightResponseArray.flatMap((response) =>
      response?.Trips?.flatMap((trip: any) =>
        trip?.Journey?.flatMap((journey: any) =>
          journey?.Segments?.map((segment: any) => segment)
        )
      )
    );

    const newObject = flights.map((flight: any) => ({
      // Flight: segment.Flight,
      // Fares: segment.Fares
      Flight: flight.Flight,
      Fares: flight.Fares,
    }));
    localStorage.setItem("FlightName", JSON.stringify(newObject));
    console.log("newObject", newObject);
  }
  // new added
  const [isClassAdded, setIsClassAdded] = useState(false);

  const handleClick = () => {
    setIsClassAdded(true);
  };

  const handleHide = () => {
    setIsClassAdded(false);
  };

  return (
    <AppLayout>
      {!catchErrors &&
      flightResponseArray.length === bookingDetailsCheck.length ? (
        <>
          <main className="container">
            <div className="tabbing-bar">
              <div className="tab-content" id="nav-tabContent">
                <div className="row checkoutmulticity_row">
                  <div className="col-sm-8">
                    {testObj?.map((flightDetails) => (
                      <div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="header_section">
                              <h3 className="h3-bold gap-2">
                                {flightDetails !== null &&
                                  flightDetails.FromName?.split("|")[1]}{" "}
                                to{" "}
                                {flightDetails !== null &&
                                  flightDetails.ToName?.split("|")[1]}
                              </h3>
                              <span className="">
                                <img
                                  loading="lazy"
                                  className="img-fluid"
                                  src={airPlainTilt}
                                  alt=""
                                />
                                {flightDetails !== null &&
                                  moment(flightDetails?.OnwardDate).format(
                                    "DD MMM YYYY"
                                  )}
                              </span>
                              <span className="">
                                {flightDetails?.Trips !== null &&
                                  flightDetails?.Trips[0]?.Journey[0]
                                    ?.Segments[0]?.Flight.Hops?.length}{" "}
                                Stop
                              </span>
                            </div>
                            <div className="header_section">
                              <h3 className="h3-bold gap-2">
                                <span>
                                  <img
                                    loading="lazy"
                                    className="img-fluid"
                                    src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${flightDetails?.Trips[0]?.Journey[0]?.Segments[0]?.Flight?.MAC}.jpg`}
                                    alt="-"
                                  />
                                </span>
                                {flightDetails?.Trips !== null &&
                                  flightDetails?.Trips[0]?.Journey[0]?.Segments[0]?.Flight?.Airline?.split(
                                    "|"
                                  )[0]}
                              </h3>
                              <span className="">
                                {" "}
                                {flightDetails?.Trips !== null &&
                                  flightDetails?.Trips[0]?.Journey[0]
                                    ?.Segments[0]?.Flight?.MAC}{" "}
                                {"-"}{" "}
                                {flightDetails?.Trips !== null &&
                                  flightDetails?.Trips[0]?.Journey[0]
                                    ?.Segments[0]?.Flight?.FlightNo}
                              </span>
                              <span style={{ textTransform: "capitalize" }}>
                                {userData.Cabin} &gt;{" "}
                                <b> {userData.Cabin} Flex</b>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <section
                              className="cheapest_section"
                              id="cheapest_section"
                            >
                              {flightDetails?.Trips !== null &&
                                flightDetails?.Trips[0]?.Journey[0]?.Segments &&
                                flightDetails?.Trips[0]?.Journey[0]?.Segments?.map(
                                  (flight: any, index: number) => (
                                    <span
                                      key={`check-connecting-${
                                        index + flight.Index + Math.random()
                                      }`}
                                    >
                                      <div className="card card_checkout">
                                        <div className="card-body">
                                          <div className="main_content gap-2 res-checkout">
                                            <div className="card_block gap-2">
                                              <div className="flight-image-section">
                                                <img
                                                  loading="lazy"
                                                  className="img-fluid"
                                                  src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${flight.Flight.MAC}.jpg`}
                                                  alt="-"
                                                />
                                              </div>
                                              <div className="card-content">
                                                <h3 className="box_heading">
                                                  {
                                                    flight?.Flight?.DepAirportName?.split(
                                                      "|"
                                                    )[1]
                                                  }{" "}
                                                  (
                                                  {flight?.Flight.DepartureCode}
                                                  ) to{" "}
                                                  {
                                                    flight?.Flight?.ArrAirportName?.split(
                                                      "|"
                                                    )[1]
                                                  }{" "}
                                                  ({flight?.Flight.ArrivalCode})
                                                </h3>
                                                <p className="flight_text text-left mb-1">
                                                  {moment(
                                                    flight.Flight.DepartureTime
                                                  ).format(
                                                    "DD MMM YYYY · HH:mm"
                                                  )}{" "}
                                                  -{" "}
                                                  {flight.ReturnDate !== "" &&
                                                    moment(
                                                      flight.Flight.ArrivalTime
                                                    ).format(
                                                      "DD MMM YYYY · HH:mm"
                                                    )}{" "}
                                                </p>
                                                <p className="flight_text text-left">
                                                  {flight.Flight.Duration} ·{" "}
                                                  {userData.Cabin}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="card-content res-checkout">
                                              <h3 className="box_heading mb-1">
                                                Check In
                                              </h3>
                                              {storeSSR &&
                                                storeSSR?.length !== 0 &&
                                                storeSSR?.map(
                                                  (ssr: {
                                                    PTC: string;
                                                    Description: string;
                                                  }) => (
                                                    <p className="flight_text text-left mb-1">
                                                      <span>
                                                        {ssr.PTC === "ADT"
                                                          ? "Adult"
                                                          : ssr.PTC === "CHD"
                                                          ? "Child"
                                                          : "Infant"}
                                                      </span>
                                                      <span>
                                                        {" "}
                                                        {
                                                          ssr?.Description?.split(
                                                            ","
                                                          )[0]
                                                        }
                                                      </span>
                                                    </p>
                                                  )
                                                )}
                                            </div>

                                            <div className="card-content res-checkout">
                                              <h3 className="box_heading mb-1">
                                                Cabin
                                              </h3>
                                              {storeSSR &&
                                                storeSSR?.length !== 0 &&
                                                storeSSR?.map(
                                                  (ssr: {
                                                    PTC: string;
                                                    Description: string;
                                                  }) => (
                                                    <p className="flight_text text-left mb-1">
                                                      <span>
                                                        {ssr.PTC === "ADT"
                                                          ? "Adult"
                                                          : ssr.PTC === "CHD"
                                                          ? "Child"
                                                          : "Infant"}
                                                      </span>
                                                      <span>
                                                        {" "}
                                                        {
                                                          ssr?.Description?.split(
                                                            ","
                                                          )[1]
                                                        }
                                                      </span>
                                                    </p>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {index !==
                                        flightDetails?.Trips[0]?.Journey[0]
                                          ?.Segments[0]?.Flight.Hops.length && (
                                        <>
                                          <div
                                            className="py-3 mb-2"
                                            key={`connecting-${index}`}
                                          >
                                            <div className="card-content">
                                              <h3 className="h3-bold">
                                                Change of planes
                                              </h3>
                                              <p className="card-text mb-0">
                                                <b>
                                                  {" "}
                                                  {calculateTimeDifference(
                                                    flight.Flight.DepartureTime,
                                                    flight.Flight.ArrivalTime
                                                    // checkConnectingFlights[index + 1].Flight.DepartureTime
                                                  )}{" "}
                                                </b>{" "}
                                                Layover in{" "}
                                                {
                                                  flight?.Flight?.ArrAirportName?.split(
                                                    "|"
                                                  )[0]
                                                }{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="header_section">
                                            <h3 className="h3-bold gap-2">
                                              <span>
                                                <img
                                                  loading="lazy"
                                                  className="img-fluid"
                                                  src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${flight?.Flight?.MAC}.jpg`}
                                                  alt="-"
                                                />
                                              </span>
                                              {
                                                flight?.Flight?.Airline?.split(
                                                  "|"
                                                )[0]
                                              }
                                            </h3>
                                            <span className="">
                                              {flight?.Flight?.MAC} {"-"}{" "}
                                              {flight?.Flight?.FlightNo}
                                            </span>
                                            <span
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {userData.Cabin} &gt;{" "}
                                              <b> {userData.Cabin} Flex</b>
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    </span>
                                  )
                                )}

                              {flightDetails?.Trips !== null &&
                              flightDetails?.Trips[0]?.Journey[0]?.Notices
                                .length === 0 ? (
                                <div className="content-body">
                                  <div className="block">
                                    <div className="content_body">
                                      <img
                                        loading="lazy"
                                        className="img-fluid"
                                        src={infoImage}
                                        alt=""
                                      />
                                      <p className="text-left">NA</p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="content-body">
                                  <div className="block">
                                    {/* <div className="content_body">
                                      <img
                                        loading="lazy"
                                        className="img-fluid"
                                        src={importantTag}
                                        alt=""
                                      />
                                      <p className="text-important">
                                        1 piece Check-in baggage is equivalent
                                        to 23 Kg for adult/child
                                      </p>
                                    </div> */}
                                    {flightDetails?.Trips !== null &&
                                      flightDetails?.Trips[0]?.Journey[0]?.Notices?.map(
                                        (notice: any) => (
                                          <div className="content_body">
                                            <img
                                              loading="lazy"
                                              className="img-fluid"
                                              src={infoImage}
                                              alt=""
                                            />
                                            <p className="text-left">
                                              {notice.Notice}
                                            </p>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )}
                            </section>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="row">
                      <div className="col-sm-12">
                        <section
                          className="cheapest_section"
                          id="cheapest_section"
                        >
                          {/* Adult Information  */}

                          <div className="card card_checkout">
                            <div className="card-body">
                              {/* <h3 className="h3-bold mb-3">
                                Adult X {userData.ADT}
                              </h3> */}

                              {adultsArray?.map((adult: any, index: number) => (
                                <CheckoutFormPassenger
                                  key={`adult-${index + adult.id}`}
                                  countries={countries as any}
                                  index={index}
                                  numberOfPassengers={adultsArray}
                                  passenger={adult}
                                  update={updateAdultAtIndex}
                                  setErrorForForms={setErrorForForms}
                                  errorForForms={submitClicked}
                                  type="adult"
                                  userData={userData.OnwardDate}
                                  storeCheckDomestic={storeCheckDomestic}
                                  flightName={
                                    getConnectingObj?.Airline?.split("|")[1]
                                  }
                                  flightMCA={getConnectingObj?.MAC}
                                />
                              ))}
                            </div>
                            <div
                              className="airline_text"
                              style={{
                                backgroundColor: "white",
                                border: "1px solid #ccc",
                                fontWeight: "400",
                                fontSize: "13px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCircleExclamation}
                                style={{
                                  color: "#f08000",
                                  marginRight: "5px",
                                  marginLeft: "5px",
                                }}
                              />
                              These details will be passed to the Airline for
                              booking
                            </div>
                          </div>

                          {/* Child section  ---------------------*/}

                          {userData.CHD > 0 && (
                            <>
                              <div className="card card_checkout">
                                <div className="card-body">
                                  {/* <h3 className="h3-bold mb-0" id="child_1">
                                    Child X {userData.CHD}
                                  </h3> */}
                                  {childrensArray?.map(
                                    (child: any, index: number) => (
                                      <CheckoutFormPassenger
                                        key={`child-${index + child.id}`}
                                        countries={countries as any}
                                        index={index}
                                        numberOfPassengers={childrensArray}
                                        passenger={child}
                                        update={updateChildrenAtIndex}
                                        errorForForms={submitClicked}
                                        setErrorForForms={setErrorForForms}
                                        type="child"
                                        userData={userData.OnwardDate}
                                        storeCheckDomestic={storeCheckDomestic}
                                        flightName={
                                          getConnectingObj?.Airline?.split(
                                            "|"
                                          )[1]
                                        }
                                        flightMCA={getConnectingObj?.MAC}
                                      />
                                    )
                                  )}
                                </div>
                                <div
                                  className="airline_text"
                                  style={{
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    fontWeight: "400",
                                    fontSize: "13px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCircleExclamation}
                                    style={{
                                      color: "#f08000",
                                      marginRight: "5px",
                                      marginLeft: "5px",
                                    }}
                                  />
                                  These details will be passed to the Airline
                                  for booking
                                </div>
                              </div>
                            </>
                          )}

                          {/* Infant section -------------- */}

                          {userData.INF > 0 && (
                            <>
                              <div className="card card_checkout">
                                <div className="card-body">
                                  {/* <h3 className="h3-bold mb-0" id="infant_1">
                                    Infant X {userData.INF}
                                  </h3> */}
                                  {infantsArray?.map((inf: any, index: any) => (
                                    <CheckoutFormPassenger
                                      key={`infant-${index + inf.id}`}
                                      countries={countries as any}
                                      index={index}
                                      numberOfPassengers={infantsArray}
                                      passenger={inf}
                                      update={updateInfantsAtIndex}
                                      errorForForms={submitClicked}
                                      setErrorForForms={setErrorForForms}
                                      type="infant"
                                      userData={userData.OnwardDate}
                                      storeCheckDomestic={storeCheckDomestic}
                                      flightName={
                                        getConnectingObj?.Airline?.split("|")[1]
                                      }
                                      flightMCA={getConnectingObj?.MAC}
                                    />
                                  ))}
                                </div>
                                <div
                                  className="airline_text"
                                  style={{
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    fontWeight: "400",
                                    fontSize: "13px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCircleExclamation}
                                    style={{
                                      color: "#f08000",
                                      marginRight: "5px",
                                      marginLeft: "5px",
                                    }}
                                  />
                                  These details will be passed to the Airline
                                  for booking
                                </div>
                              </div>
                            </>
                          )}

                          {/* addons section here */}
                          <AddonsSection
                            paidSSRList={paidSSRList}
                            seatpaidSSRList={seatpaidSSRList}
                            adultsArray={adultsArray}
                            childrenArray={childrensArray}
                            infantArray={infantsArray}
                            userData={userData}
                            setAddonDetails={setAddonDetails}
                            addonDetails={addonDetails}
                            setMealAddonDetails={setMealAddonDetails}
                            mealAddonDetails={mealAddonDetails}
                            setSeatAddonDetails={setSeatAddonDetails}
                            seatAddonDetails={seatAddonDetails}
                            setSportBaggageDetails={setSportBaggageDetails}
                            sportBaggageDetails={sportBaggageDetails}
                            priorityBaggageDetails={priorityBaggageDetails}
                            setPriorityBaggageDetails={
                              setPriorityBaggageDetails
                            }
                            // seatAddonDetails={seatAddonDetails}
                            onClick={handleClick}
                            onHide={handleHide}
                            checkConnectingFlights={checkConnectingFlights}
                            setAddonDetailsChild={setAddonDetailsChild}
                            addonDetailsChild={addonDetailsChild}
                            setAddonDetailsInfant={setAddonDetailsInfant}
                            addonDetailsInfant={addonDetailsInfant}
                            setMealAddonDetailsChild={setMealAddonDetailsChild}
                            mealAddonDetailsChild={mealAddonDetailsChild}
                            setMealAddonDetailsInfant={
                              setMealAddonDetailsInfant
                            }
                            mealAddonDetailsInfant={mealAddonDetailsInfant}
                            setSeatAddonDetailsChild={setSeatAddonDetailsChild}
                            seatAddonDetailsChild={seatAddonDetailsChild}
                            setSeatAddonDetailsInfant={
                              setSeatAddonDetailsInfant
                            }
                            seatAddonDetailsInfant={seatAddonDetailsInfant}
                            setSportBaggageDetailsChild={
                              setSportBaggageDetailsChild
                            }
                            sportBaggageDetailsChild={sportBaggageDetailsChild}
                            setSportBaggageDetailsInfant={
                              setSportBaggageDetailsInfant
                            }
                            sportBaggageDetailsInfant={
                              sportBaggageDetailsInfant
                            }
                            priorityBaggageDetailsChild={
                              priorityBaggageDetailsChild
                            }
                            setPriorityBaggageDetailsChild={
                              setPriorityBaggageDetailsChild
                            }
                            priorityBaggageDetailsInfant={
                              priorityBaggageDetailsInfant
                            }
                            setPriorityBaggageDetailsInfant={
                              setPriorityBaggageDetailsInfant
                            }
                            // totalFareCalc={totalFareCalc}
                            // updateBaggageAddons={handleBooking}
                          />
                          <div style={{ padding: "15px" }}>
                            <div
                              style={{
                                display: "flex",
                                gap: "7px",
                                marginBottom: "12px",
                              }}
                            >
                              <input
                                type="checkbox"
                                id="gst-toggle"
                                style={{ height: "25px", width: "17px" }}
                                onChange={() => setCheckGst(!checkGst)}
                              />
                              <label
                                htmlFor="gst-toggle"
                                style={{ fontWeight: "600" }}
                              >
                                I have a GST number (optional)
                              </label>
                            </div>
                            {checkGst === true && (
                              <div>
                                <div className="card-content">
                                  <div className="mb-4 text-left1">
                                    <div
                                      style={{
                                        display: "grid",
                                        padding: "5px 25px",
                                      }}
                                    >
                                      <label
                                        htmlFor="company-number"
                                        style={{ marginBottom: "5px" }}
                                      >
                                        Registration number
                                      </label>
                                      <input
                                        type="text"
                                        id="company-number"
                                        placeholder="Registration Number"
                                        style={{
                                          borderRadius: "4px",
                                          border: "1px solid #bdbdbd",
                                          fontSize: "14px",
                                          padding: "5px",
                                        }}
                                        onChange={
                                          handleRegistrationNumberChange
                                        }
                                      />
                                    </div>

                                    <div
                                      style={{
                                        display: "grid",
                                        padding: "5px 25px",
                                      }}
                                    >
                                      <label
                                        htmlFor="company-gst"
                                        style={{ marginBottom: "5px" }}
                                      >
                                        Company Name
                                      </label>
                                      <input
                                        type="text"
                                        id="company-gst"
                                        placeholder="Company Name"
                                        style={{
                                          borderRadius: "4px",
                                          border: "1px solid #bdbdbd",
                                          fontSize: "14px",
                                          padding: "5px",
                                        }}
                                        onChange={handleCompanyNameChange}
                                      />
                                    </div>

                                    <div
                                      style={{
                                        display: "grid",
                                        padding: "5px 25px",
                                      }}
                                    >
                                      <label
                                        htmlFor="email-gst"
                                        style={{ marginBottom: "5px" }}
                                      >
                                        Email
                                      </label>
                                      <input
                                        type="text"
                                        id="email-gst"
                                        placeholder="Email"
                                        style={{
                                          borderRadius: "4px",
                                          border: "1px solid #bdbdbd",
                                          fontSize: "14px",
                                          padding: "5px",
                                        }}
                                        onChange={handleEmailChange}
                                      />
                                    </div>

                                    <div
                                      style={{
                                        display: "grid",
                                        padding: "5px 25px",
                                      }}
                                    >
                                      <label
                                        htmlFor="full-name-gst"
                                        style={{
                                          marginBottom: "5px",
                                        }}
                                      >
                                        Mobile No.
                                      </label>
                                      <input
                                        type="text"
                                        id="mobile-gst"
                                        placeholder="Mobile"
                                        style={{
                                          borderRadius: "4px",
                                          border: "1px solid #bdbdbd",
                                          fontSize: "14px",
                                          padding: "5px",
                                          textTransform: "uppercase",
                                        }}
                                        onChange={handleMobileChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <h3 className="h3-bold">Contact Information</h3>

                            <label className="email_text">
                              <span style={{ marginRight: "5px" }}>
                                <img
                                  loading="lazy"
                                  className="img-fluid"
                                  src={envelopImg}
                                  alt=""
                                />
                              </span>
                              Your booking information will be sent here..
                            </label>
                          </div>
                          <div className="row pb-3">
                            <div className="col-sm-6 my-2 text-left1 mobile-listing">
                              <div className="mobile-select">
                                <div className="mobile-data-list">
                                  <div className="dropdown">
                                    <a
                                      className="dropdown-toggle"
                                      href="/"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <span className="country-code">
                                        +{phoneCodeValue}
                                        <img
                                          loading="lazy"
                                          src={carretDown}
                                          className="caret"
                                          height=""
                                          alt=""
                                        />{" "}
                                      </span>
                                    </a>
                                    <ul className="dropdown-menu">
                                      <li>
                                        <div className="country-code-box">
                                          <img
                                            loading="lazy"
                                            src={mangifyGlass}
                                            className="search-icon"
                                            height=""
                                            alt=""
                                          />
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="Country or Code"
                                            value={searchText}
                                            onChange={(e) =>
                                              setSearchText(e.target.value)
                                            }
                                          />
                                        </div>
                                      </li>
                                      {countries
                                        ?.filter(
                                          ({ name, phoneCode, nameCode }) =>
                                            `${name} ${phoneCode} ${nameCode}`
                                              .toLowerCase()
                                              .includes(
                                                searchText.toLowerCase()
                                              )
                                        )
                                        ?.map(
                                          (
                                            { name, phoneCode, nameCode },
                                            idx
                                          ) => (
                                            <li
                                              key={`phone-code-${
                                                phoneCode + idx
                                              }`}
                                              onClick={() =>
                                                setPhoneCodeValue(phoneCode)
                                              }
                                            >
                                              <span className="dropdown-item">
                                                <span>
                                                  {" "}
                                                  {name} (+{phoneCode})
                                                </span>{" "}
                                                <span
                                                  className="code-country"
                                                  style={{
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  {nameCode}{" "}
                                                </span>
                                              </span>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  style={{
                                    border: guestLoginIndication
                                      ? "2px solid #ff7f00"
                                      : "",
                                  }}
                                  id="exampleFormControlInput1"
                                  placeholder="Add your mobile no."
                                  value={phoneValue}
                                  onChange={(e) => {
                                    setPhoneValue(e.target.value);

                                    setGuestLoginIndication(false);
                                  }}
                                />
                              </div>
                              {phoneValue === "" && mobileError && (
                                <p style={{ color: "red", margin: "4px" }}>
                                  {" "}
                                  Please enter your Mobile no.
                                </p>
                              )}
                            </div>
                            <div className="col-sm-6 my-2">
                              <input
                                type="email"
                                style={{
                                  border: guestLoginIndication
                                    ? "2px solid #ff7f00"
                                    : "",
                                }}
                                className="form-control"
                                placeholder="Email"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={emailValue}
                                onChange={(e) => {
                                  setEmailValue(e.target.value);
                                  const testEmail = emailRegex.test(
                                    e.target.value
                                  );
                                  setErrorForForms(!testEmail);
                                  setGuestLoginIndication(false);
                                }}
                              />
                              {emailValue === "" && emailError && (
                                <p style={{ color: "red", margin: "4px" }}>
                                  {" "}
                                  Please enter your email
                                </p>
                              )}
                              {emailValue !== "" &&
                                !emailRegex.test(emailValue) && (
                                  <p style={{ color: "red", margin: "4px" }}>
                                    {" "}
                                    Please enter a valid email
                                  </p>
                                )}
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  {/* Sidebar checkout component*/}
                  {testObj && (
                    <MultiCitySideBarCheckout
                      getObjRules={testObj}
                      flightSearchDetails={flightSearchDetails}
                      phoneValue={phoneValue}
                      emailValue={emailValue}
                      setGuestLoginIndication={setGuestLoginIndication}
                      slabsListData={slabsListData}
                      flightData={testObj}
                      sellingPrice={sellingPrice}
                      setSellingPrice={setSellingPrice}
                    />
                  )}
                </div>
                <span>
                  {" "}
                  By clicking on <b>Continue to payment</b>, I agree with the
                  Booking Policies, Privacy Policy & Terms.
                </span>
                <div
                  className="inline-text payment_btn"
                  style={{ marginTop: "18px" }}
                >
                  <button
                    className="btn btn-login"
                    type="button"
                    onClick={() => {
                      onConfirmHandler();
                    }}
                  >
                    Continue to payment
                  </button>
                </div>
              </div>
            </div>
          </main>
          {/* // Edit modal  start */}
          {toggleEditModal && (
            <div
              className="modal fade show"
              id="exampleModal_Edit"
              aria-labelledby="exampleModalLabel_Edit"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              style={{ display: toggleEditModal ? "block" : "none" }}
              role="dialog"
            >
              <div className="overlay"> </div>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3
                      className="checkout-heading"
                      id="exampleModalLabel_Edit"
                    >
                      {soldOutCheck ? "Tickets sold out" : "Review Details"}
                    </h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setToggleEditModal(false)}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="content-body">
                      {soldOutCheck ? (
                        <div style={{ display: "flex" }}>
                          <img
                            loading="lazy"
                            style={{ maxWidth: "25%" }}
                            className="img-fluid"
                            src={`https://d3c46yygxatk0.cloudfront.net/cdn/images/sorry-emoji.png`}
                            alt="flight"
                          />
                          <p
                            className="edit_lead"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                              fontSize: "18px",
                            }}
                          >
                            Sorry, the requested seats are sold out. Please
                            click 'Continue Search' to find another flight.
                          </p>
                        </div>
                      ) : (
                        <p className="edit_lead">
                          Please ensure that the spelling of your name and other
                          details match with your travel document/govt. ID, as
                          these cannot be changed later. Errors might lead to
                          cancellation penalties.
                        </p>
                      )}
                    </div>
                  </div>
                  {!soldOutCheck && (
                    <div className="card mx-2 border-0">
                      <h3 className="checkout-heading px-3">ADULT</h3>
                      {adultsArray?.map((ad, index) => (
                        <div
                          className="card-body pt-0"
                          key={`adults-arr-${index}`}
                        >
                          <div className="box-list">
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="edit_lead mb-1">Title</p>
                              <p
                                className="edit_lead mb-1"
                                style={{ textTransform: "uppercase" }}
                              >
                                {ad.title}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="edit_lead mb-1">First Name</p>
                              <p
                                className="edit_lead mb-1"
                                style={{ textTransform: "uppercase" }}
                              >
                                {ad.firstName}
                              </p>
                            </div>
                          </div>
                          <div className="box-list">
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="edit_lead mb-1">Last Name</p>
                              <p
                                className="edit_lead mb-1"
                                style={{ textTransform: "uppercase" }}
                              >
                                {ad.lastName}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      {childrensArray?.length > 0 &&
                        childrensArray?.map((ad, index) => (
                          <div
                            className="card-body pt-0"
                            key={`child-arr-${index}`}
                          >
                            <div className="box-list">
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="edit_lead mb-1">Title</p>
                                <p
                                  className="edit_lead mb-1"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {ad.title}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="edit_lead mb-1">First Name</p>
                                <p
                                  className="edit_lead mb-1"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {ad.firstName}
                                </p>
                              </div>
                            </div>
                            <div className="box-list">
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="edit_lead mb-1">Last Name</p>
                                <p
                                  className="edit_lead mb-1"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {ad.lastName}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}

                      {infantsArray.length > 0 &&
                        infantsArray?.map((ad, index) => (
                          <div
                            className="card-body pt-0"
                            key={`infant-arr-${index}`}
                          >
                            <div className="box-list">
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="edit_lead mb-1">Title</p>
                                <p
                                  className="edit_lead mb-1"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {ad.title}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="edit_lead mb-1">First Name</p>
                                <p
                                  className="edit_lead mb-1"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {ad.firstName}
                                </p>
                              </div>
                            </div>
                            <div className="box-list">
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="edit_lead mb-1">Last Name</p>
                                <p
                                  className="edit_lead mb-1"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {ad.lastName}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}

                  <div className="modal-footer">
                    {soldOutCheck ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-login"
                          style={{ backgroundColor: "#ff5d62" }}
                          onClick={() => navigate("/")}
                        >
                          Back to Home page
                        </button>
                        <button
                          type="button"
                          className="btn btn-login"
                          onClick={() => navigate("/search-flights")}
                        >
                          Continue to search
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-login"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setToggleEditModal(false)}
                        >
                          EDIT
                        </button>
                        <button
                          type="button"
                          className="btn btn-login"
                          data-bs-dismiss="modal"
                          onClick={submitItinerary}
                        >
                          Confirm
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* // Edit modal end  */}

          {/* // Flight Fare comparison modal   */}
          <div
            className="modal fade"
            id="change-to-flight-popup"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            style={{ display: "none" }}
            aria-modal="true"
            role="dialog"
          >
            <div className="overlay"> </div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content site-modal site_modal">
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="row modal-row1">
                    <div className="container px-3 py-2">
                      <div className="right-section">
                        <div className="card_block">
                          <div className="flight-image-section py-2">
                            <img
                              loading="lazy"
                              className="img-fluid"
                              src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${getConnectingObj?.MAC}.jpg`}
                              alt="flight"
                            />
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="card-content">
                              <div className="d-flex justify-content-center align-content-center m-auto py-2 gap-3">
                                <div className="first-block">
                                  <h3 className="h3-bold text-center">
                                    {
                                      flightDetailsObject?.data?.FromName?.split(
                                        "|"
                                      )[1]
                                    }
                                  </h3>
                                </div>
                                <div className="second-block">
                                  <img
                                    loading="lazy"
                                    className="img-fluid"
                                    src={leftArrow}
                                    alt="flight"
                                  />
                                </div>
                                <div className="third-block">
                                  <h3 className="h3-bold text-center">
                                    {" "}
                                    {
                                      flightDetailsObject?.data?.ToName?.split(
                                        "|"
                                      )[1]
                                    }
                                  </h3>
                                </div>
                              </div>
                              <p className="flight_text text-center text-dark mb-1 px-2">
                                We regret on a change in the Fare for the Flight
                                you have selected. You may proceed with the new
                                fare or Return to the Results page and select a
                                different Flight
                              </p>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex border-1 justify-content-center align-content-center m-auto py-2 gap-3">
                            <div className="first-block">
                              <span className="text-danger text-center">
                                {" "}
                                Old Fare
                              </span>
                              <h3 className="h3-bold text-center">₹ 59924 </h3>
                            </div>
                            <div className="second-block mx-0 my-auto">
                              <img
                                loading="lazy"
                                className="img-fluid"
                                src={leftArrow}
                                alt="flight"
                              />
                            </div>
                            <div className="third-block">
                              <span className="text-dark text-center">
                                {" "}
                                New Fare
                              </span>
                              <h3 className="h3-bold text-center">₹ 60911</h3>
                            </div>
                          </div>
                          <hr />

                          <div className="d-flex gap-5 align-items-center justify-content-center mb-0 px-3 py-3">
                            <div className="d-flex justify-content-center align-items-center gap-3 w-100">
                              <div className="card-content">
                                <button className="btn btn-login" type="button">
                                  Return{" "}
                                </button>
                              </div>
                              <div className="card-content">
                                <button className="btn btn-login" type="button">
                                  Continue
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <main className="container">
          <div className="tabbing-bar">
            {!isFlightLoading && <h1>Sorry, try another combination</h1>}
          </div>
        </main>
      )}

      {isFlightLoading && <AirplaneLoader />}
      {showModal && <TimeRemaining />}

      {/* {toggleTermsModal && (
        <AgreeToTermsAndConditions
          setToggleTermsModal={setToggleTermsModal}
          setToggleEditModal={setToggleEditModal}
        />
      )} */}

      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
