import calender from "../../../assets/images/calender.svg";
import status from "../../../assets/images/status.png";
import location from "../../../assets/images/location.png";
import LazyImage from "../../../pages/LazyImage";
import moment from "moment";
import { useState } from "react";
import { updatePaymentStatus } from "../../../api/AdminAPI";
import { toast } from "react-toastify";
import { valuesAndMeanings } from "../../../utils/helpers";

interface BookingManagementProps {
  storedFlightList: any;
  searchTerm: string;
  setReCallAPI: any;
}

const refundStatusLabels = [
  { name: "Not Applicable", status: 0 },
  { name: "Pending", status: 1 },
  { name: "Processing", status: 2 },
  { name: "In Progress", status: 3 },
  { name: "Refund Initiated", status: 4 },
  { name: "Refund Failed", status: 5 },
  { name: "Partially Refunded", status: 6 },
  { name: "Cancel Penalties Completed", status: 7 },
];

export const BookingManagement = ({
  storedFlightList,
  searchTerm,
  setReCallAPI,
}: BookingManagementProps) => {
  const [editFlightDetails, setEditFlightDetails] = useState<any>();

  const onSavePayment = (flightDetail: any, statusText: any) => {
    updatePaymentStatus({
      txnId: flightDetail.paymentData.txnId,
      refundStatus: statusText,
    })
      .then((res) => {
        toast.success("successfully updated payment status", {
          toastId: "payment-status-success",
        });
        setReCallAPI(true);
      })
      .catch((err) =>
        toast.error("Error in updating payment status", {
          toastId: "payment-status",
        })
      );
    setReCallAPI(false);
  };

  function findTextByCode(code: string) {
    const foundValue = valuesAndMeanings.find((item) => item.code === code);
    if (foundValue) {
      return foundValue.text;
    } else {
      return "Canceled";
    }
  }

  const getTripStatus = (trip: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const onwardDates = new Date(trip?.flightBookingResponseData?.OnwardDate);
    onwardDates?.setHours(0, 0, 0, 0);

    if (today < onwardDates) {
      return "Upcoming";
    } else if (today > onwardDates) {
      return "Completed";
    } else {
      return "Canceled";
    }
  };

  function mergeObjectsByPTC(arr: any[]) {
    const result: any = {};

    arr.forEach((obj) => {
      const ptc = obj.PTC;

      if (!result[ptc]) {
        result[ptc] = 0;
      }

      result[ptc]++;
    });

    const summary = Object.entries(result).map(
      ([ptc, count]: any) =>
        `${count} ${
          ptc === "ADT" ? "Adult" : ptc === "CHD" ? "Child" : "Infant"
        }${count > 1 ? "s" : ""}`
    );

    return summary.join(", ");
  }

  return (
    <>
      {/* <!-- BookingManagement --> */}
      <section className="upcoming_section mt-4" id="BookingManagement_section">
        {/* <!-- first --> */}
        {/* {storedFlightList.length > 0 ? (
          <>
            {storedFlightList.map((flight) => ( */}
        {storedFlightList.length > 0 ? (
          <>
            {storedFlightList
              ?.filter(
                (flight: {
                  flightBookingResponseData: {
                    ContactInfo: any[];
                    Trips: any[];
                  };
                  TransactionID: any;
                }) =>
                  flight?.flightBookingResponseData?.ContactInfo[0]?.FName?.toLowerCase()?.includes(
                    searchTerm?.toLowerCase()
                  ) ||
                  flight?.flightBookingResponseData?.ContactInfo[0]?.LName?.toLowerCase()?.includes(
                    searchTerm?.toLowerCase()
                  ) ||
                  // flight.TransactionID.toLowerCase().includes(searchTerm.toLowerCase())
                  String(flight.TransactionID)
                    .toLowerCase()
                    .includes(searchTerm?.toLowerCase()) // Convert to string first
              )
              .map(
                (flight: {
                  paymentData: any;
                  _id: any;
                  flightBookingResponseData: {
                    ContactInfo: any[];
                    Trips: any[];
                    Pax: any[];
                    Status: string;
                    BookingDate: moment.MomentInput;
                  };
                  TransactionID: any;
                  flightStatus: string;
                }) => (
                  <div
                    className="card card_upcmoing"
                    key={`flight-list-${flight._id}`}
                  >
                    {flight?.flightBookingResponseData?.Trips &&
                      flight?.flightBookingResponseData?.Trips.length > 0 && (
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="travel_section mb-3">
                                <div className="text-left">
                                  <div style={{ display: "flex" }}>
                                    <h3 className="fs-5 mb-0">
                                      {flight?.flightBookingResponseData
                                        ?.ContactInfo[0]?.FName +
                                        " " +
                                        flight?.flightBookingResponseData
                                          ?.ContactInfo[0]?.LName}{" "}
                                      x{" "}
                                      {
                                        flight.flightBookingResponseData.Pax
                                          .length
                                      }
                                    </h3>
                                    <span
                                      style={{
                                        marginLeft: "15px",
                                        border: "1px solid #ccc",
                                        padding: "4px",
                                      }}
                                    >
                                      Ref No. {flight.TransactionID}
                                    </span>
                                  </div>
                                </div>
                                <div className="block_btn d-flex align-items-center">
                                  <div className="date_icon">
                                    <LazyImage
                                      className="date_imatge"
                                      src={calender}
                                      alt=""
                                    />
                                    <span className="date_text">
                                      {moment(
                                        flight.flightBookingResponseData
                                          .BookingDate
                                      ).format("Do MMM YYYY")}
                                    </span>
                                  </div>

                                  <div className="card-content">
                                    <button
                                      className="btn btn-cancel"
                                      type="button"
                                      style={{
                                        color: "black",
                                        border: "2px solid rgb(202 154 0)",
                                      }}
                                    >
                                      {" "}
                                      {findTextByCode(flight.flightStatus)}
                                    </button>
                                  </div>
                                  <div className="card-content">
                                    <button
                                      className="btn btn-cancel"
                                      type="button"
                                      style={{
                                        color: "black",
                                        border: "2px solid rgb(147 152 0)",
                                      }}
                                    >
                                      {" "}
                                      {findTextByCode(
                                        flight.flightStatus
                                      ).includes("Failed")
                                        ? "Failed"
                                        : findTextByCode(
                                            flight.flightStatus
                                          ).includes("Success")
                                        ? "Success"
                                        : getTripStatus(flight)}
                                    </button>
                                  </div>
                                  <div className="card-content">
                                    <button
                                      className="btn btn-refund-status"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#payment"
                                      onClick={() =>
                                        setEditFlightDetails(flight)
                                      }
                                    >
                                      <span className="mx-2">
                                        <LazyImage
                                          className="cross_imatge"
                                          src={status}
                                          alt=""
                                        />
                                      </span>
                                      Edit
                                    </button>
                                  </div>
                                  <div className="card-content">
                                    {refundStatusLabels &&
                                      !findTextByCode(
                                        flight.flightBookingResponseData.Status
                                      ).includes("Success") && (
                                        <div className="input-group mb-0">
                                          {/* <span> Refund Status : </span>{" "} */}
                                          <button
                                            className="btn btn-refund-status dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            {
                                              (
                                                refundStatusLabels.find(
                                                  (label) =>
                                                    label.status ===
                                                    flight.paymentData
                                                      .refundStatus
                                                ) ?? { name: "Unknown" }
                                              ).name
                                            }
                                          </button>
                                          <ul className="dropdown-menu">
                                            {refundStatusLabels.map(
                                              (label, index) => (
                                                <li key={index}>
                                                  <span
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                      onSavePayment(
                                                        flight,
                                                        label.status
                                                      );
                                                    }}
                                                  >
                                                    {label.name}
                                                  </span>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                              {flight.flightBookingResponseData.Trips.map(
                                (trip: any, index: number) => (
                                  <>
                                    {trip.Journey.map((journey: any) => (
                                      <div
                                        className="main_content col_section"
                                        style={{
                                          borderBottom:
                                            index !==
                                            flight.flightBookingResponseData
                                              ?.Trips?.length -
                                              1
                                              ? "1px solid #ccc"
                                              : "",
                                          marginBottom:
                                            flight.flightBookingResponseData
                                              ?.Trips?.length - 1
                                              ? "9px"
                                              : "",
                                        }}
                                      >
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                              <span className="image_bookin">
                                                <LazyImage
                                                  src={location}
                                                  alt=""
                                                />
                                              </span>
                                              Departure city{" "}
                                            </p>
                                            <h3 className="block_heading">
                                              {
                                                journey.Segments[0].Flight.DepAirportName.split(
                                                  "|"
                                                )[1]
                                              }
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                              <span className="image_bookin">
                                                <LazyImage
                                                  src={calender}
                                                  alt=""
                                                />
                                              </span>
                                              Arrival city
                                            </p>
                                            <h3 className="block_heading">
                                              {" "}
                                              {
                                                journey.Segments[0].Flight.ArrAirportName.split(
                                                  "|"
                                                )[1]
                                              }
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                              <span className="image_bookin">
                                                <LazyImage
                                                  src={calender}
                                                  alt=""
                                                />
                                              </span>
                                              Arrival Date
                                            </p>
                                            <h3 className="block_heading">
                                              {moment(
                                                journey.Segments[0].Flight
                                                  .ArrivalTime
                                              ).format("DD MMM YYYY")}
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                              <span className="image_bookin">
                                                <LazyImage
                                                  src={calender}
                                                  alt=""
                                                />
                                              </span>
                                              Departure Date
                                            </p>
                                            <h3 className="block_heading">
                                              {moment(
                                                journey.Segments[0].Flight
                                                  .DepartureTime
                                              ).format("DD MMM YYYY")}
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <h3 className="block_heading">
                                              Flight No.{" "}
                                            </h3>
                                            <p className="card-text">
                                              {" "}
                                              {
                                                journey.Segments[0].Flight.MAC
                                              } -{" "}
                                              {
                                                journey.Segments[0].Flight
                                                  .FlightNo
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        <div className="block">
                                          <div className="card-content">
                                            <h3 className="block_heading">
                                              Travel Class
                                            </h3>
                                            <p className="card-text">
                                              {journey.Segments[0].Flight
                                                .Cabin === "E"
                                                ? "Economy"
                                                : journey.Segments[0].Flight
                                                    .Cabin === "PE"
                                                ? "Premium Economy"
                                                : journey.Segments[0].Flight
                                                    .Cabin === "B"
                                                ? "Business"
                                                : "First Class"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="block">
                                          <div className="card-content">
                                            <h3 className="block_heading">
                                              Trip Type.{" "}
                                            </h3>
                                            <p className="card-text">
                                              {" "}
                                              {flight.flightBookingResponseData
                                                .Trips.length === 2
                                                ? "Round Trip"
                                                : flight
                                                    .flightBookingResponseData
                                                    .Trips.length === 1
                                                ? "One Way"
                                                : "Multi City"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="block">
                                          <div className="card-content">
                                            <h3 className="block_heading">
                                              Passengers{" "}
                                            </h3>
                                            <p className="card-text">
                                              {" "}
                                              {mergeObjectsByPTC(
                                                flight.flightBookingResponseData
                                                  ?.Pax
                                              )}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="block">
                                          <div className="card-content">
                                            <h3 className="block_heading">
                                              Airline PNR
                                            </h3>
                                            <p
                                              className="card-text"
                                              style={{ textAlign: "center" }}
                                            >
                                              {" "}
                                              {journey.Segments[0].Flight
                                                .AirlinePNR === ""
                                                ? "-"
                                                : journey.Segments[0].Flight
                                                    .AirlinePNR}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="block">
                                          <div className="card-content">
                                            <h3 className="block_heading">
                                              System PNR
                                            </h3>
                                            <p
                                              className="card-text"
                                              style={{ textAlign: "center" }}
                                            >
                                              {" "}
                                              {journey.Segments[0].Flight
                                                .CRSPNR === ""
                                                ? "-"
                                                : journey.Segments[0].Flight
                                                    .CRSPNR}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                )
              )}
          </>
        ) : (
          <h2>No Flight Data Available</h2>
        )}

        {/* ))}
          </>
        ) : (
          <div>No Flight Booking Available</div>
        )} */}
      </section>
      {/* <!-- pagination --> */}
      <div id="pagination">
        <div className="d-flex justify-content-between align-items-center mt-3 pagination_data">
          <div className="col-sm-3 d-flex gap-2 align-items-center">
            <div className="page-item">
              <p className="mb-0">Page Item</p>
            </div>

            <select
              className="form-select"
              aria-label="Default select example"
              style={{
                width: "70px",
              }}
            >
              <option>10</option>
              <option value="1">15</option>
              <option value="2">20</option>
              <option value="3">25</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li className="page-item disabled">
                <a className="page-link" href="#" aria-disabled="true">
                  Previous
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              {/* <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li> */}
              <li className="page-item">
                <a className="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="payment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="paymentLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content px-3">
            <div className="modal-header">
              <h5 className="modal-title" id="paymentLabel">
                Payment Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {editFlightDetails && (
              <div className="modal-body">
                <div className="block">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0">txnId :</h3>
                    <h3 className="block_heading mb-0">
                      {editFlightDetails.paymentData.txnId}
                    </h3>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0">status :</h3>
                    <h3 className="block_heading mb-0">
                      {" "}
                      {editFlightDetails.paymentData.status === 2
                        ? "Success"
                        : editFlightDetails.paymentData.status === 1
                        ? "Pending"
                        : editFlightDetails.paymentData.status === 3
                        ? "Failed"
                        : "Canceled"}
                    </h3>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0">amount :</h3>
                    <h3 className="block_heading mb-0">
                      {editFlightDetails.NetAmount}
                    </h3>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0"> productInfo :</h3>
                    <h3 className="block_heading mb-0"> Flight</h3>
                  </div>

                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0">mode :</h3>
                    <h3 className="block_heading mb-0">
                      {editFlightDetails.paymentData.paymentResponse?.mode}
                    </h3>
                  </div>

                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0">mihPay id :</h3>
                    <h3 className="block_heading mb-0">
                      {editFlightDetails.paymentData.paymentResponse?.mihpayid}
                    </h3>
                  </div>

                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0">Payment Source :</h3>
                    <h3 className="block_heading mb-0">
                      {
                        editFlightDetails.paymentData.paymentResponse
                          ?.payment_source
                      }
                    </h3>
                  </div>

                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0">Bank ref Number :</h3>
                    <h3 className="block_heading mb-0">
                      {
                        editFlightDetails.paymentData.paymentResponse
                          ?.bank_ref_num
                      }
                    </h3>
                  </div>

                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0">Discount :</h3>
                    <h3 className="block_heading mb-0">
                      {editFlightDetails.paymentData.paymentResponse?.discount}
                    </h3>
                  </div>

                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0">
                      net_amount_debit (Exact debited amount by PayU) :
                    </h3>
                    <h3 className="block_heading mb-0">
                      {
                        editFlightDetails.paymentData.paymentResponse
                          ?.net_amount_debit
                      }
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {/* 
            {refundStatusLabels &&
              !findTextByCode(editFlightDetails?.flightStatus).includes(
                "Success"
              ) && (
                <div className="input-group mb-0">
                  <span> Refund Status : </span>{" "}
                  <button
                    className="btn btn-refund-status dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {
                      (
                        refundStatusLabels.find(
                          (label) => label.status === selectedRefundStatus
                        ) ?? { name: "Unknown" }
                      ).name
                    }
                  </button>
                  <ul className="dropdown-menu">
                    {refundStatusLabels.map((label, index) => (
                      <li key={index}>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => handleRefundStatusChange(label.status)}
                        >
                          {label.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )} */}

            <div className="modal-footer justify-content-start">
              <button
                type="button"
                className="btn btn-login search_record"
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
