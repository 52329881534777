export const formatFlightClass = (flightClass: string): string => {
  switch (flightClass) {
    case "economy":
      return "E";
    case "premium economy":
      return "PE";
    case "business":
      return "B";
    case "first class":
      return "F";
    default:
      return "";
  }
};

export enum refundStatus {
  notApplicable = 0,
  pending = 1,
  processing = 2,
  inProgress = 3,
  refundInitiated = 4,
  refundFailed = 5,
  partiallyRefunded = 6,
  cancelPenaltiesCompleted = 7,
}

export const valuesAndMeanings = [
  { code: "BO0", text: "Booking Onward Success" },
  { code: "BO1", text: "Booking Onward Failed" },
  { code: "BO0,BR0", text: "Booking Success" },
  { code: "B01,BR1", text: "Booking Failed" },
  { code: "BO1,BR0", text: "Booking Onward Failed, Booking Return Success" },
  { code: "BO0,BR1", text: "Booking Onward Success, Booking Return Failed" },
  { code: "TO0", text: "Ticketing Onward Success" },
  { code: "TO1", text: "Ticketing Onward Failed" },
  {
    code: "TO0,TR0",
    text: "Ticketing Success",
  },
  { code: "TO1,TR1", text: "Ticketing Failed" },
  {
    code: "TO1,TR0",
    text: "Ticketing Onward Failed, Ticketing Return Success",
  },
  {
    code: "TO0,TR1",
    text: "Ticketing Onward Success, Ticketing Return Failed",
  },
  {
    code: "HO0,HR0",
    text: "Hold Booking Success",
  },
  {
    code: "HO1,HR1",
    text: "Hold Booking Failed",
  },
  {
    code: "HO1,HR0",
    text: "Hold Booking Onward Failed, Hold Booking Return Success",
  },
  {
    code: "HO0,HR1",
    text: "Hold Booking Onward Success, Hold Booking Return Failed",
  },
  {
    code: "CR",
    text: "Canceled",
  },
];

// formats the currency and amount displayed in fare Rules (flight list -> flight details -> fare rules)
export const formatAmountAndCurrency = (
  amount: string,
  currencyCode?: string
): string => {
  const numericAmount = parseFloat(amount.trim()); // Trim any spaces and attempt to convert to number

  // Check if the amount is a valid number
  if (isNaN(numericAmount)) {
    return amount; // If not a number, return the original string (e.g., "Non-refundable")
  }

  if (!currencyCode) {
    return numericAmount.toLocaleString(); // No currency symbol if no code is provided
  }

  return numericAmount.toLocaleString("en-IN", {
    style: "currency",
    currency: currencyCode, // Use the provided currency code
  });
};
