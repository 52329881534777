import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import { TalkToAgents } from "../../components/TalkToAgent";
import bufferGif from "../../assets/images/buffergif.gif";
import { useEffect } from "react";
import LazyImage from "../../pages/LazyImage";

import { useLocation, useNavigate } from "react-router-dom";
import { flightStartPay, storeFlightData } from "../../api/FlightAPI";

export const PaymentBuffer = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const jsonDataString = new URLSearchParams(location.search).get("data");
  const jsonData = JSON.parse(decodeURIComponent(String(jsonDataString)));

  const itineraryTUI = localStorage.getItem("createITernTUI");
  const parsedItinerary = JSON.parse(String(itineraryTUI));

  const smartTUI = localStorage.getItem("smartTUI");

  useEffect(() => {
    if (jsonData.status) {
      const oldPayment = localStorage.getItem("paymentObj");
      const startPayDetails = {
        TUIStartPay: String(smartTUI),
      };

      const existingPaymentObj = oldPayment ? JSON.parse(oldPayment) : {};

      const updatedPaymentObj = {
        ...existingPaymentObj,
        ...startPayDetails,
        ...jsonData,
      };

      localStorage.setItem("paymentObj", JSON.stringify(updatedPaymentObj));

      const paymentPriceHandler = async () => {
        await flightStartPay({
          TransactionID: Number(jsonData?.txnId),
          PaymentAmount: 0,
          NetAmount: Number(jsonData.paymentResponse.net_amount_debit),
          BrowserKey: "e3f560de2c422e2ed29f4e4b8ab27017",
          ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
          TUI: String(smartTUI),
          Hold: false,
          Promo: null,
          PaymentType: "",
          BankCode: "",
          GateWayCode: "",
          MerchantID: "",
          PaymentCharge: 0,
          ReleaseDate: "",
          OnlinePayment: false,
          DepositPayment: true,
          Card: {
            Number: "",
            Expiry: "",
            CVV: "",
            CHName: "",
            Address: "",
            City: "",
            State: "",
            Country: "",
            PIN: "",
            International: false,
            SaveCard: false,
            FName: "",
            LName: "",
            EMIMonths: "0",
          },
          VPA: "",
          CardAlias: "",
          QuickPay: null,
          RMSSignature: "",
          TargetCurrency: "",
          TargetAmount: 0,
          ServiceType: "ITI",
        })
          .then((res) => {
            storeFlightData({
              paymentId: jsonData.paymentId,
              userId: existingPaymentObj?.userId,
              TUI: String(smartTUI),
              NetAmount: String(jsonData.paymentResponse.net_amount_debit),
              TransactionID: jsonData?.txnId,
              paymentStatus: jsonData?.status,
              paymentTxnId: jsonData?.txnId,
            })
              .then((res) => console.log(res, "payment store response"))
              .catch((err) => console.log(err));

            if (typeof parsedItinerary === "object") {
              navigate("/booking-confirmation");
            } else {
              navigate("/booking-multi-city");
            }
          })
          .catch((error) => console.log(error));
      };
      if (jsonData?.status === 2) {
        paymentPriceHandler();
      }
    }

    if (jsonData === null) {
      localStorage.setItem("paymentObj", JSON.stringify({ status: 3 }));
      if (typeof parsedItinerary === "object") {
        navigate("/booking-confirmation");
      } else {
        navigate("/booking-multi-city");
      }
    }
    if (typeof parsedItinerary === "object") {
      navigate("/booking-confirmation");
    } else {
      navigate("/booking-multi-city");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonData]);

  return (
    <AppLayout>
      <div className="container">
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="text-center row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="text-center">
                <h1 className="page_not_found">
                  <LazyImage src={bufferGif} alt="buffer" />
                </h1>
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <p className="fs-3">
                <span className="text-danger">Please wait!</span>
              </p>
              <p className="lead">
                While we are processing your payment request
              </p>
            </div>
          </div>
        </div>
      </div>
      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
