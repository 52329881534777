import calendarSVG from "../../../assets/images/calender.svg";
import React from "react";
import { Calendar } from "react-date-range";
import moment from "moment";
import plusIcon from "../../../assets/images/plus_icon.png";
import minusIcon from "../../../assets/images/minus_icon.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { hotelAdmin, hotelDestinationListFilter } from "../../../api/HotelAPI";
import { toast } from "react-toastify";
import {SearchBarActivity} from "./SearchBarActivity";

import AutoCompleteHotel from "../shared/AutoCompleteHotel";

interface SearchHotelProps {
  activeTab: string;
}

export const SearchActivity = () => {
    return (
      <>
        <div className="search-bar-wrapper">
          <SearchBarActivity />
        </div>
      </>
    );
}