import axios from "axios";
import {
  cancelBookingURL,
  checkChangedFareURL,
  downloadBookingURL,
  expressSearchURL,
  flightItineraryStatusURL,
  flightItineraryURL,
  flightPaymentPayuURL,
  flightStartPayURL,
  getAllAirportsURL,
  getCurrentLocationAirportURL,
  getFlightBaggageURL,
  getFlightFareURL,
  getFlightInfoURL,
  getGeneratedSignedTokenURL,
  getSSRforBookingURL,
  getSeatLayoutURL,
  getSmartPriceURL,
  getTUITokenForExpressSearchURL,
  retrieveBookingURL,
  saveFlightGuestURL,
  sendSMSForBookingURL,
  smartPriceForTokenURL,
  storeFlightDataURL,
  updateFlightStoreDataURL,
  webSettingsURL,
} from "./endpoints";

interface flightDetailsTypes {
  ADT: number;
  CHD: number;
  INF: number;
  Cabin: string;
  Source: string;
  Mode: string;
  TUI: string;
  FareType: string;
  ClientID: string;
  IsMultipleCarrier: boolean;
  IsRefundable: boolean;
  SecType: string;
  YTH: number;
  //PreferedAirlines:string;
  Trips: [
    {
      From: string;
      To: string;
      OnwardDate: string;
      ReturnDate: string;
      FromCity?: string;
      FromArptName?: string;
      ToCity?: string;
      ToArptName?: string;
    }
  ];
  Parameters: {
    Airlines: string;
    GroupType: string;
    Refundable: string;
    IsDirect: boolean;
    IsStudentFare: boolean;
    IsNearbyAirport: boolean;
    IsSeniorCitizen: boolean;
  };
}

interface flightInfoPayloadProps {
  Trips: any;
  TripType: string;
  ClientID: string;
}

//  interface webSettingsPayloadProps {
//   ClientID: string;
//   TUI:any;

// }

interface flightFarePayloadProps {
  Trips: any;
  ClientID: string;
  Mode: string;
  Options: string;
  Source: string;
  TripType: string;
}

interface flightBaggagePayloadProps {
  PaidSSR: boolean;
  ClientID: string;
  Source: string;
  Trips: any;
}

interface getSmartPriceForTokenPayloadProps {
  ClientID?: string;
  Source: string;
  Options: string;
  Mode: string;
  TripType?: string;
  Trips: [
    {
      Amount: number;
      Index: string;
      ChannelCode?: null;
      OrderID?: number;
      TUI: string;
    }
  ];
}

interface getSmartPriceURLPayloadProps {
  ClientID: string;
  TUI: string;
}

interface getSSRforBookingPayloadProps {
  ClientID: string;
  PaidSSR: boolean;
  Source: string;
  Trips: [
    {
      TUI: string;
      Amount: number;
      OrderID: number;
      Index: string;
    }
  ];
}

interface checkChangedFarePayloadProps {
  TUI: string;
  ClientID: string;
}

interface flightItineraryPayloadProps {
  TUI: string;
  ServiceEnquiry: string;
  ContactInfo: {
    Title: string;
    FName: string;
    LName: string;
    Mobile: string;
    DestMob: string;
    Phone: string;
    Email: string;
    Language: string;
    Address: string;
    CountryCode: string;
    MobileCountryCode: string;
    DestMobCountryCode: string;
    State: string;
    City: string;
    PIN: string;
    GSTCompanyName: string;
    GSTTIN: string;
    GstMobile: string;
    GSTEmail: string;
    UpdateProfile: boolean;
    IsGuest: boolean;
    SaveGST: boolean;
  };
  DestinationContactInfo: {
    Address1: string;
    Address2: string;
    City: string;
    Mobile: string;
    Phone: string;
    Email: string;
    CountryCode: string;
    MobileCountryCode: string;
    State: string;
    PIN: string;
  };
  Travellers: any[];
  PLP: any[];
  // SSR: null;
  SSR: any[];
  CrossSell: any[];
  CrossSellAmount: number;
  EnableFareMasking: boolean;
  SSRAmount: number;
  ClientID: string;
  DeviceID: string;
  AppVersion: string;
  AgentTourCode: string;
  NetAmount: number;
  BRulesAccepted: string;
}

interface flightPaymentPayloadProps {
  userId: string;
  txnId: string;
  amount: string;
  productInfo: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  Pg: string;
  udf5: string;
}

interface saveGuestPayloadProps {
  email: string;
  mobileNumber: string;
  countryCode: string;
}

interface flightStartPayPayloadProps {
  TransactionID: number;
  PaymentAmount: number;
  NetAmount: number;
  BrowserKey: string;
  ClientID: string;
  TUI: string;
  Hold: boolean;
  Promo: null;
  PaymentType: string;
  BankCode: string;
  GateWayCode: string;
  MerchantID: string;
  PaymentCharge: number;
  ReleaseDate: string;
  OnlinePayment: boolean;
  DepositPayment: boolean;
  Card: {
    Number: string;
    Expiry: string;
    CVV: string;
    CHName: string;
    Address: string;
    City: string;
    State: string;
    Country: string;
    PIN: string;
    International: boolean;
    SaveCard: boolean;
    FName: string;
    LName: string;
    EMIMonths: string;
  };
  VPA: string;
  CardAlias: string;
  QuickPay: null;
  RMSSignature: string;
  TargetCurrency: string;
  TargetAmount: number;
  ServiceType: string;
}

interface flightItineraryStatusPayloadProps {
  TransactionID: number;
  TUI: string;
}

interface retrieveBookingPayloadProps {
  TUI: string;
  ClientID: string;
  ReferenceNumber: string;
  ReferenceType: string;
  ServiceType: string;
}

interface downloadBookingPayloadProps {
  ReferenceNumber: number;
}

interface storeFlightDataPayloadProps {
  paymentId: string;
  userId: string;
  TUI: string;
  NetAmount: string;
  TransactionID: string;
  paymentStatus: number;
  paymentTxnId: string;
}

interface cancelBookingPayloadProps {
  ClientID: string;
  ClientIP: string;
  Remarks: string;
  TUI: string;
  TransactionID: number;
  Trips: any[];
}

interface sendSMSForBookingPayloadProps {
  TUI: string;
  ClientID: string;
  ReferenceNumber: string;
  ReferenceType: string;
  ServiceType: string;
}

interface getSeatLayoutPayloadProps {
  ClientID: string;
  Source: string;
  Trips: any[];
}

export async function getGeneratedSignedToken() {
  try {
    const response = await axios.get(getGeneratedSignedTokenURL);
    return response.data;
  } catch (error) {
    console.error("Error in generating signed token:", error);
    throw error;
  }
}

export async function getAllAirports() {
  const signedTokenJSON = localStorage.getItem("flightToken");

  if (signedTokenJSON) {
    const signedToken = JSON.parse(String(signedTokenJSON));

    try {
      const response = await axios.get(getAllAirportsURL, {
        headers: {
          Authorization: `Bearer ${signedToken.signedToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in getting all airports:", error);
      throw error;
    }
  } else {
    console.log("No signed token found. Skipping API call.");
    return null;
  }
}

export async function getCurrentLocationAirports() {
  const signedTokenJSON = localStorage.getItem("flightToken");

  if (signedTokenJSON) {
    const signedToken = JSON.parse(signedTokenJSON);

    try {
      const response = await axios.get(getCurrentLocationAirportURL, {
        headers: {
          Authorization: `Bearer ${signedToken.signedToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in getting current airport:", error);
      throw error;
    }
  } else {
    console.log("No signed token found. Skipping API call.");
    return null;
  }
}

export async function expressSearch(flightDetails: flightDetailsTypes) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload: flightDetailsTypes = { ...flightDetails };

  try {
    const response = await axios.post(expressSearchURL, payload, { headers });
    return response.data;
  } catch (error) {
    console.error("Error in express Search", error);
    throw error;
  }
}

export async function getTUItokenAfterExpressSearch(token: string) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload = { TUI: token };

  try {
    const response = await axios.post(getTUITokenForExpressSearchURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in getting TUI token:", error);
    throw error;
  }
}

export async function getWebsettings() {
  try {
    const response = await axios.post(webSettingsURL);
    return response.data;
  } catch (error) {
    console.error("Error in fetching web settings", error);
    throw error;
  }
}

export async function getFlightInfo(flightInfoPayload: flightInfoPayloadProps) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload = { ...flightInfoPayload };

  try {
    const response = await axios.post(getFlightInfoURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in getting Flight URL", error);
    throw error;
  }
}

export async function getFlightFare(flightFarePayload: flightFarePayloadProps) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload = { ...flightFarePayload };

  try {
    const response = await axios.post(getFlightFareURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in getting Flight Fare", error);
    throw error;
  }
}

export async function getFlightBaggage(
  flightBaggagePayload: flightBaggagePayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload = { ...flightBaggagePayload };

  try {
    const response = await axios.post(getFlightBaggageURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in getting Flight Fare", error);
    throw error;
  }
}

export async function getSmartPriceForToken(
  getSmartPriceForTokenPayload: getSmartPriceForTokenPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload = { ...getSmartPriceForTokenPayload };

  try {
    const response = await axios.post(smartPriceForTokenURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in smart price for token", error);
    throw error;
  }
}

export async function getSmartPriceForBooking(
  getSmartPriceURLPayload: getSmartPriceURLPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");
  const userToken = localStorage.getItem("token");

  const signedToken = JSON.parse(String(signedTokenJSON));

  if (!userToken) {
    console.error("User token is missing or invalid.");
  }

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
    token: userToken || null,
  };

  const payload = { ...getSmartPriceURLPayload };

  try {
    const response = await axios.post(getSmartPriceURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in smart price for token", error);
    throw error;
  }
}

export async function getSSRforBooking(
  getSSRforBookingPayload: getSSRforBookingPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload = { ...getSSRforBookingPayload };

  try {
    const response = await axios.post(getSSRforBookingURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in SSR for booking", error);
    throw error;
  }
}

export async function checkChangedFare(
  checkChangedFarePayload: checkChangedFarePayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload = { ...checkChangedFarePayload };

  try {
    const response = await axios.post(checkChangedFareURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in checking domestic", error);
    throw error;
  }
}

export async function flightItinerary(
  flightItineraryPayload: flightItineraryPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");
  const userToken = localStorage.getItem("token");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
    token: userToken || null,
  };

  const payload = { ...flightItineraryPayload };

  try {
    const response = await axios.post(flightItineraryURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in checking domestic", error);
    throw error;
  }
}

export async function flightPayment(
  flightPaymentPayload: flightPaymentPayloadProps
) {
  const userToken = localStorage.getItem("token");

  const headers = {
    token: userToken || null,
  };

  const payload = { ...flightPaymentPayload };

  try {
    const response = await axios.post(flightPaymentPayuURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in payment", error);
    throw error;
  }
}

export async function saveFlightGuest(saveGuestPayload: saveGuestPayloadProps) {
  const payload = { ...saveGuestPayload };

  try {
    const response = await axios.post(saveFlightGuestURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in saving guest", error);
    throw error;
  }
}

export async function storeFlightData(
  storeFlightDataPayload: storeFlightDataPayloadProps
) {
  const payload = { ...storeFlightDataPayload };

  try {
    const response = await axios.post(storeFlightDataURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in saving guest", error);
    throw error;
  }
}

export async function updateStoreFlightData(updateStoreFlightDataPayload: any) {
  const payload = { ...updateStoreFlightDataPayload };

  try {
    const response = await axios.post(updateFlightStoreDataURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error in saving guest", error);
    throw error;
  }
}

export async function flightStartPay(
  flightStartPayload: flightStartPayPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");
  const userToken = localStorage.getItem("token");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const payload = { ...flightStartPayload };

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
    token: userToken || null,
  };

  try {
    const response = await axios.post(flightStartPayURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in start pay", error);
    throw error;
  }
}

export async function flightItineraryStatus(
  flightItineraryStatusPayload: flightItineraryStatusPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");
  const userToken = localStorage.getItem("token");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const payload = { ...flightItineraryStatusPayload };

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
    token: userToken || null,
  };

  try {
    const response = await axios.post(flightItineraryStatusURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in start pay", error);
    throw error;
  }
}

export async function retrieveBooking(
  retrieveBookingPayload: retrieveBookingPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");
  const userToken = localStorage.getItem("token");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const payload = { ...retrieveBookingPayload };

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
    token: userToken || null,
  };

  try {
    const response = await axios.post(retrieveBookingURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in start pay", error);
    throw error;
  }
}

export async function downloadBooking(
  downloadBookingPayload: downloadBookingPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const payload = { ...downloadBookingPayload };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  try {
    const response = await axios.post(downloadBookingURL, payload, {
      headers,
      responseType: "arraybuffer",
    });
    return response.data;
  } catch (error) {
    console.error("Error in start pay", error);
    throw error;
  }
}

export async function cancelFlightBooking(
  cancelBookingPayload: cancelBookingPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");
  const userToken = localStorage.getItem("token");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const payload = { ...cancelBookingPayload };

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
    token: userToken || null,
  };

  try {
    const response = await axios.post(cancelBookingURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in cancelation api", error);
    throw error;
  }
}

export async function getSeatLayout(
  getSeatLayoutPayload: getSeatLayoutPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const payload = { ...getSeatLayoutPayload };

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  try {
    const response = await axios.post(getSeatLayoutURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in seat layout api", error);
    throw error;
  }
}

export async function sendSMSForBooking(
  sendSMSForBookingPayload: sendSMSForBookingPayloadProps
) {
  const signedTokenJSON = localStorage.getItem("flightToken");

  const signedToken = JSON.parse(String(signedTokenJSON));

  const payload = { ...sendSMSForBookingPayload };

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  try {
    const response = await axios.post(sendSMSForBookingURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in SMS api", error);
    throw error;
  }
}
