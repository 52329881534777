import calender from "../../../assets/images/calender.svg";
import crossIcon from "../../../assets/images/cross_icon.png";
import location from "../../../assets/images/location.png";
import bufferGif from "../../../assets/images/buffergif.gif";
import LazyImage from "../../../pages/LazyImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import status from "../../../assets/images/status.png";
import { faArrowRight, faPlaneUp } from "@fortawesome/free-solid-svg-icons";
import "./travelAndHotel.css";
import { Key, useEffect, useState } from "react";
import {
  cancelFlightBooking,
  downloadBooking,
  retrieveBooking,
  updateStoreFlightData,
} from "../../../api/FlightAPI";
import { getUserProfile, upComingTrips } from "../../../api/UserProfile";
import moment from "moment";
import { valuesAndMeanings } from "../../../utils/helpers";
import { toast } from "react-toastify";

export const UpcomingTrips = () => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showPoliciesModal, setShowPoliciesModal] = useState(false);
  const [downloadTicketModal, setDownloadTicketModal] = useState(false);
  const [paxAlert, setPaxAlert] = useState(false);
  const [withChildOrInfant, setWithChildOrInfant] = useState(false);
  const [acceptFare, setAcceptFare] = useState(false);
  const [remark, setRemark] = useState("");
  const [upcomingTripsList, setUpcomingTripsList] = useState<any[]>([]);
  const [storeCancelDataArray, setStoreCancelDataArray] = useState<any>();
  const [storeRefId, setStoreRefId] = useState(0);
  const [selectedPax, setSelectedPax] = useState<any[]>([]);
  const [trips, setTrips] = useState<any>();
  const [userValues, setUserValues] = useState<{ id: string }>({ id: "" });

  const togglePaxSelection = (person: any) => {
    const index = selectedPax.findIndex((pax) => pax.ID === person.ID);

    if (index !== -1) {
      const updatedSelection = [...selectedPax];
      updatedSelection.splice(index, 1);
      setSelectedPax(updatedSelection);
    } else {
      setSelectedPax([...selectedPax, person]);
    }
  };

  useEffect(() => {
    getUserProfile()
      .then((response: { data: { _id: any } }) => {
        setUserValues({ id: response.data._id });
      })
      .catch((err: any) => {
        console.log("profile error", err);
      });
  }, []);

  useEffect(() => {
    if (storeCancelDataArray) {
      const hasChildrenOrInfants = storeCancelDataArray?.Pax?.some(
        (passenger: { PTC: string }) =>
          passenger.PTC === "CHD" || passenger.PTC === "INF"
      );

      const hasAtLeastOneAdult = selectedPax?.some(
        (passenger) => passenger.PTC === "ADT"
      );

      const hasAtLeastOneChild = selectedPax?.some(
        (passenger) => passenger.PTC === "CHD"
      );

      const hasAtLeastOneInfant = selectedPax?.some(
        (passenger) => passenger.PTC === "INF"
      );

      if (hasAtLeastOneAdult && hasChildrenOrInfants) {
        setWithChildOrInfant(true);
        setPaxAlert(
          hasAtLeastOneAdult && hasAtLeastOneInfant && hasAtLeastOneChild
        );
      } else {
        setWithChildOrInfant(false);
        // setPaxAlert(hasAtLeastOneAdult);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPax]);

  useEffect(() => {
    upComingTrips()
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setUpcomingTripsList(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const getCancelationPolicyData = async () => {
      await retrieveBooking({
        TUI: "",
        ClientID: "",
        ReferenceNumber: String(storeRefId),
        ReferenceType: "T",
        ServiceType: "FLT",
      })
        .then((res) => {
          if (res.data) {
            setStoreCancelDataArray(res.data);
          }
        })
        .catch((err) => console.log(err));
    };

    getCancelationPolicyData();
  }, [storeRefId]);

  const closeModal = () => {
    setShowModal(false);
    setSelectedPax([]);
    setPaxAlert(false);
    setAcceptFare(false);
    setRemark("");
  };

  const openPoliciesModal = () => {
    setShowPoliciesModal(true);
  };

  const closePoliciesModal = () => {
    setShowPoliciesModal(false);
  };

  const openTicketModal = () => {
    setDownloadTicketModal(true);
  };

  const closeTicketModal = () => {
    setDownloadTicketModal(false);
  };

  const downloadTicketPDF = () => {
    downloadBooking({
      ReferenceNumber: Number(storeRefId),
    })
      .then((res) => {
        const blob = new Blob([res], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "booking.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => console.log(err));
  };

  const getCancelationPolicyDetails = () => {
    setShowModal(true);
  };

  const findStatusText = (code: any) => {
    const status = valuesAndMeanings.find((item) => item.code === code);
    return status ? status.text : "Unknown Status";
  };

  const getPaxesData = selectedPax.map((pax: any) => ({
    ID: pax.ID,
    Ticket: "",
  }));

  const getTrips = storeCancelDataArray?.Trips?.map(
    (trip: { Journey: any[] }) => ({
      Journey: trip.Journey.map((journey: { Segments: any[] }) => ({
        Segments: journey.Segments.map((segment) => ({
          CRSPNR: segment.Flight.CRSPNR,
          PAX: getPaxesData,
        })),
      })),
    })
  );

  const submitCancellation = (trips: any) => {
    cancelFlightBooking({
      ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
      ClientIP: "",
      Remarks: remark,
      TUI: "",
      TransactionID: Number(storeRefId),
      Trips: getTrips,
    })
      .then((res) => {
        toast.success("Successfully canceled flight booking", {
          toastId: "success-cancel",
        });
        retrieveBooking({
          TUI: "",
          ClientID: "",
          ReferenceNumber: String(storeRefId),
          ReferenceType: "T",
          ServiceType: "FLT",
        })
          .then((response) => {
            if (response.data) {
              updateStoreFlightData({
                ...response?.data,
                userId: String(userValues?.id),
                TransactionID: String(storeRefId),
              })
                .then((res) => console.log(res))
                .catch(() =>
                  toast.error("Error in Flight cancelation update", {
                    toastId: "error-1",
                  })
                );
            }
          })
          .catch((err) =>
            toast.error("Error in Flight cancelation update", {
              toastId: "error-2",
            })
          );
      })
      .catch((err) =>
        toast.error("Error in Flight cancelation", {
          toastId: "error-cancel",
        })
      );
  };

  const rulesTable = (rules: any) => {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: "50%" }}></th>
            <th>Amount (Adult)</th>
            <th>Amount (Child)</th>
            <th>Amount (Infant)</th>
            <th>Currency Code</th>
          </tr>
        </thead>
        <tbody>
          {rules.map((rule: any, index: Key | null | undefined) => (
            <tr key={index}>
              <td colSpan={6}>
                {rule.Rule.length > 0 ? (
                  rule.Rule.map((subRule: any, subIndex: any) => (
                    <div key={subIndex}>
                      <table className="table table-bordered">
                        <tbody>
                          {subRule.Info.map(
                            (info: any, infoIndex: Key | null | undefined) => (
                              <tr key={infoIndex}>
                                <td style={{ width: "30%" }}>
                                  {info.Description}
                                </td>
                                <td>
                                  {info.AdultAmount === ""
                                    ? "Nill"
                                    : info.AdultAmount}
                                </td>
                                <td>
                                  {info.ChildAmount === ""
                                    ? "Nill"
                                    : info.ChildAmount}
                                </td>
                                <td>
                                  {" "}
                                  {info.InfantAmount === ""
                                    ? "Nill"
                                    : info.InfantAmount}
                                </td>
                                <td>
                                  {" "}
                                  {info.CurrencyCode === ""
                                    ? "Nill"
                                    : info.CurrencyCode}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  ))
                ) : (
                  <h5>No Rules Available</h5>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <section className="upcoming_section mt-4" id="upcoming_trips_section">
      {/* <!-- first --> */}
      <div className="card card_upcmoing">
        <div className="card-body">
          {upcomingTripsList && upcomingTripsList?.length > 0 ? (
            <div className="row">
              {upcomingTripsList.map((trip: any) => (
                <div className="card card_upcmoing border-0" key={trip._id}>
                  <div className="card-body px-0">
                    <div className="row">
                      <div
                        className="col-sm-12 col-md-12"
                        style={{
                          borderBottom: "1px solid #ccc",
                          paddingBottom: "9px",
                        }}
                      >
                        <div className="travel_section mb-3">
                          <div className="text-left d-flex gap-2">
                            <div className="image_bookin">
                              <span className="image_bookin">
                                <FontAwesomeIcon icon={faPlaneUp} />{" "}
                              </span>
                            </div>
                            <h3 className="name-title mb-0">
                              {trip.flightBookingResponseData.ContactInfo[0]
                                .FName +
                                " " +
                                trip.flightBookingResponseData.ContactInfo[0]
                                  .LName}{" "}
                              x {trip?.flightBookingResponseData?.Pax?.length}
                              <span
                                style={{
                                  padding: "0px 10px",
                                }}
                              >
                                Ref. No
                              </span>
                              <span>{trip.TransactionID}</span>
                            </h3>
                          </div>
                          <div className="block_btn d-flex align-items-center">
                            <div className="date_icon">
                              <LazyImage
                                className="date_imatge"
                                src={calender}
                                alt=""
                              />
                              <span className="date_text">
                                {" "}
                                {moment(
                                  trip.flightBookingResponseData.BookingDate
                                ).format("Do MMM YYYY")}
                              </span>
                            </div>

                            <span
                              style={{
                                border: "1px solid #ec3237",
                                padding: "5px 8px",
                                borderRadius: "8px",
                                fontSize: "small",
                              }}
                            >
                              {" "}
                              {findStatusText(trip.flightStatus)}
                            </span>

                            <div className="card-content">
                              <button
                                className="btn btn-cancel_policy"
                                type="button"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setTrips(trip);
                                  openPoliciesModal();
                                }}
                              >
                                Cancellation policies
                              </button>
                            </div>

                            <div className="card-content">
                              {findStatusText(trip.flightStatus).includes(
                                "Failed"
                              ) ? (
                                <button
                                  className="btn btn-refund-status"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#payment"
                                >
                                  <span className="mx-2">
                                    <LazyImage
                                      className="cross_imatge"
                                      src={status}
                                      alt=""
                                    />
                                  </span>
                                  Refund status
                                  {/* {
                                    (
                                      refundStatusLabels.find(
                                        trip.paymentData.refundStatus
                                      ) ?? { name: "Unknown" }
                                    ).name
                                  } */}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-cancel"
                                  type="button"
                                  style={{
                                    background: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setStoreRefId(trip.TransactionID);
                                    getCancelationPolicyDetails();
                                  }}
                                >
                                  <span className="mx-2">
                                    <LazyImage
                                      className="cross_imatge"
                                      src={crossIcon}
                                      alt=""
                                    />
                                  </span>
                                  Cancel
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="main_content col_section">
                          {trip.flightBookingResponseData.Trips.map(
                            (flight: any) => (
                              <>
                                {flight.Journey.map((journey: any) => (
                                  <div
                                    className="main_content "
                                    style={{ columnGap: "110px" }}
                                    onClick={() => {
                                      openTicketModal();
                                      setStoreRefId(trip.TransactionID);
                                    }}
                                  >
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                          <span className="image_bookin">
                                            <LazyImage src={location} alt="" />
                                          </span>
                                          Departure city{" "}
                                        </p>
                                        <h3 className="block_heading">
                                          {" "}
                                          {
                                            journey.Segments[0].Flight.DepAirportName.split(
                                              "|"
                                            )[1]
                                          }
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                          <span className="image_bookin">
                                            <LazyImage src={calender} alt="" />
                                          </span>
                                          Arrival city
                                        </p>
                                        <h3 className="block_heading">
                                          {
                                            journey.Segments[0].Flight.ArrAirportName.split(
                                              "|"
                                            )[1]
                                          }
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                          <span className="image_bookin">
                                            <LazyImage src={calender} alt="" />
                                          </span>
                                          Arrival Date
                                        </p>
                                        <h3 className="block_heading">
                                          {moment(
                                            journey.Segments[0].Flight
                                              .ArrivalTime
                                          ).format("DD MMM YYYY")}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                          <span className="image_bookin">
                                            <LazyImage src={calender} alt="" />
                                          </span>
                                          Departure Date
                                        </p>
                                        <h3 className="block_heading">
                                          {moment(
                                            journey.Segments[0].Flight
                                              .DepartureTime
                                          ).format("DD MMM YYYY")}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <h3 className="block_heading">
                                          Flight No.{" "}
                                        </h3>
                                        <p className="card-text">
                                          {" "}
                                          {journey.Segments[0].Flight.MAC}{" "}
                                          {" - "}
                                          {journey.Segments[0].Flight.FlightNo}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <h3 className="block_heading">
                                          Travel Class
                                        </h3>
                                        <p className="card-text">
                                          {" "}
                                          {journey.Segments[0].Flight.Cabin ===
                                          "E"
                                            ? "Economy"
                                            : journey.Segments[0].Flight
                                                .Cabin === "PE"
                                            ? "Premium Economy"
                                            : journey.Segments[0].Flight
                                                .Cabin === "B"
                                            ? "Business"
                                            : "First Class"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <h5>No Upcoming Trips</h5>
          )}
        </div>
      </div>

      <div
        className={`modal ${downloadTicketModal ? "show" : ""}`}
        tabIndex={-1}
        role="dialog"
        style={{ display: downloadTicketModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ border: "1px solid" }}>
            <div className="modal-header">
              <h5 className="modal-title" style={{ fontWeight: "600" }}>
                Download Booking
              </h5>
              <button
                type="button"
                className="close"
                onClick={closeTicketModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Would you like to retrieve your ticket by downloading it?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeTicketModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={downloadTicketPDF}
                style={{ backgroundColor: "red" }}
              >
                Download Ticket
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal ${showPoliciesModal ? "show" : ""}`}
        tabIndex={-1}
        role="dialog"
        style={{
          display: showPoliciesModal ? "block" : "none",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ border: "1px solid" }}>
            <div className="modal-header">
              <h5 className="modal-title" style={{ fontWeight: "600" }}>
                Cancelling Policies
              </h5>
              <button
                type="button"
                className="close"
                onClick={closePoliciesModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {trips && (
              <div className="modal-body">
                {trips.flightBookingResponseData?.Rules?.length > 0 ? (
                  rulesTable(trips.flightBookingResponseData.Rules)
                ) : (
                  <h5>No Rules Available</h5>
                )}
              </div>
            )}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closePoliciesModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal ${showModal ? "show" : ""}`}
        tabIndex={-1}
        role="dialog"
        style={{
          display: showModal ? "block" : "none",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ border: "1px solid" }}>
            <div className="modal-header">
              <h5 className="modal-title" style={{ fontWeight: "600" }}>
                Cancel Booking
              </h5>
              <button type="button" className="close" onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {storeCancelDataArray && storeCancelDataArray?.Rules?.length > 0 ? (
              <div>
                <div>
                  <h5
                    style={{
                      fontSize: "18px",
                      padding: "10px",
                      backgroundColor: "gainsboro",
                    }}
                  >
                    {storeCancelDataArray.From}{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ color: "#000000" }}
                    />{" "}
                    {storeCancelDataArray.To}
                  </h5>
                  <ul>
                    {storeCancelDataArray?.Pax.map((person: any) => (
                      <li
                        style={{
                          display: "flex",
                          gap: "10px",
                          padding: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ width: "15px" }}
                          checked={selectedPax.some(
                            (pax) => pax.ID === person.ID
                          )}
                          onChange={() => togglePaxSelection(person)}
                        />
                        <span
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "600",
                            fontSize: "14px",
                            marginTop: "3px",
                          }}
                        >
                          {person.FName + " " + person.LName}{" "}
                          <div>
                            PNR -{" "}
                            {storeCancelDataArray.Trips[0].Journey[0]
                              .Segments[0].Flight.CRSPNR === ""
                              ? "-"
                              : storeCancelDataArray.Trips[0].Journey[0]
                                  .Segments[0].Flight.CRSPNR}
                          </div>
                        </span>
                        |
                        <span style={{ marginTop: "1px" }}>
                          {person.PTC === "ADT"
                            ? "Adult"
                            : person.PTC === "CHD"
                            ? "Child"
                            : "Infant"}
                        </span>
                        <h6
                          style={{
                            marginLeft: "auto",
                            fontSize: "medium",
                            backgroundColor: "#ffdec6",
                            paddingRight: "5px",
                            paddingLeft: "5px",
                            borderRadius: "6px",
                            marginBottom: "20px",
                          }}
                        >
                          {findStatusText(storeCancelDataArray.Status)}
                        </h6>
                      </li>
                    ))}
                  </ul>

                  {selectedPax.length > 0 && withChildOrInfant && !paxAlert && (
                    <div style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontSize: "13px",
                          color: "red",
                        }}
                      >
                        You cannot cancel Adult Pax If there are Child and
                        Infant in this journey
                      </span>
                    </div>
                  )}

                  <span style={{ paddingLeft: "12px" }}>Remarks:</span>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      value={remark}
                      style={{
                        width: "96%",
                        margin: "auto",
                      }}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal-body">No Cancellation Data present</div>
            )}

            <div className="modal-footer">
              <div>
                <input
                  id="accept-fare"
                  type="checkbox"
                  style={{
                    width: "24px",
                    height: "16px",
                  }}
                  checked={acceptFare}
                  onChange={() => setAcceptFare(!acceptFare)}
                />
                <label
                  htmlFor="accept-fare"
                  style={{
                    fontSize: "medium",
                    fontWeight: "600",
                    padding: "5px",
                  }}
                >
                  I Accept Fare Rules
                </label>
              </div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  closeModal();
                  submitCancellation(storeCancelDataArray.Trips);
                }}
                disabled={
                  (selectedPax.length > 0 && withChildOrInfant && !paxAlert) ||
                  selectedPax.length < 1 ||
                  !acceptFare
                }
                style={{ backgroundColor: "red" }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 17%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <LazyImage src={bufferGif} alt="buffer" />
        </div>
      )}
    </section>
  );
};
