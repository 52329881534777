import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import { PageRoutes } from "./pageRoutes";
import AWS from "aws-sdk";
import { BrowserRouter, HashRouter } from "react-router-dom";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { AuthProvider } from "./components/context/AuthenticationContext";
import { ToastContainer } from "react-toastify";
import { FlightLoaderProvider } from "./components/forms/shared/useFlightLoader";
import { AddonsProvider } from "./components/forms/flight/addonccontext";
import ReactGA from "react-ga";
import { useEffect } from "react";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const TRACKING_ID = "G-8KEHWWBHYH"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <AddonsProvider>
          <BrowserRouter>
            <FlightLoaderProvider>
              <PageRoutes />
            </FlightLoaderProvider>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </BrowserRouter>
        </AddonsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
