import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FaSearch } from "react-icons/fa";
import { CiLocationArrow1, CiLocationOn } from "react-icons/ci";
import searchImage from "../../../assets/images/image 61.png";
import {Link, useNavigate} from "react-router-dom";

export const SearchBarActivity = () => {
  const [input, setInput] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showSearchList, setShowSearchList] = useState(true);

  const navigate = useNavigate();

  const whereRef = useRef<HTMLDivElement>(null);
  const whenRef = useRef<HTMLDivElement>(null);

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (whereRef.current && !whereRef.current.contains(event.target as Node)) {
      setShowSearchList(false);
    }
    if (whenRef.current && !whenRef.current.contains(event.target as Node)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleWhereFocus = () => {
    setShowSearchList(true);
    setShowCalendar(false);
  };

  const handleWhenClick = () => {
    setShowCalendar(!showCalendar);
    setShowSearchList(false);
  };

  return (
    <>
      <div className="where-input-wrapper" ref={whereRef}>
        <h5 className="search-heading">Where to?</h5>
        <input
          placeholder="Search for a place or activity"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onFocus={handleWhereFocus}
        />
        <div
          className={`where-search-list ${
            input && showSearchList ? "visible" : ""
          }`}
        >
          <ul>
            <li>
              <div className="content-wrapper">
                <div className="icon-block">
                  <CiLocationArrow1 />
                </div>
                <div className="content-block">
                  <h5>Nearby Location</h5>
                </div>
              </div>
            </li>
            <li>
              <div className="content-wrapper">
                <Link to="/destination-page">
                  <div className="icon-block">
                    <CiLocationOn />
                  </div>
                  <div className="content-block">
                    <h5>Searched Name</h5>
                    <p>Location</p>
                  </div>
                </Link>
              </div>
            </li>
            <li>
              <div className="content-wrapper">
                <Link to="/activity-page">
                  <div className="icon-block">
                    <img src={searchImage} alt="" />
                  </div>
                  <div className="content-block">
                    <h5>Searched Name</h5>
                    <p>Location</p>
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="when-input-wrapper"
        ref={whenRef}
        onClick={handleWhenClick}
      >
        <h5 className="search-heading">When</h5>
        <input
          placeholder="Select Dates"
          value={selectedDate.toDateString()}
          readOnly
        />
        <div className="input-search-icon">
          <FaSearch />
        </div>
        {showCalendar && (
          <div className="calendar-wrapper">
            <Calendar date={selectedDate} onChange={handleDateSelect} />
          </div>
        )}
      </div>
    </>
  );
};
