export const host = "https://api.maashree.com";

// Admin Login
export const adminLoginURL = `${host}/api/admin/login`;

// Admin dashboard
export const adminDashboard = `${host}/api/admin/admin-dashboard`;

export const hotelDealsListURL = `https://api.maashree.com/api/offers/hotel-deals-list`;

export const updateHotelDealsURL = `${host}/api/offers/update-hotel-deals`;

export const getPaymentStatusURL = `${host}/api/payments/payment-details-by-txnId`;

export const updatePaymentStatusURL = `${host}/api/payments/update-refund-status`;

export const slabsListURL = `${host}/api/admin/slab-list`;

export const updateSlabsInAdminURL = `${host}/api/admin/update-slabs`;

export const deleteSlabsURL = `${host}/api/admin/delete-slabs`;

export const createSlabsURL = `${host}/api/admin/add-slabs`;

const userAPIs = `${host}/api/users`;

// user API
export const registerOrLoginURL = `${userAPIs}/is-user-registered`;

export const otpVerifyURL = `${userAPIs}/verify-otp`;

export const resendOtpURL = `${userAPIs}/resend-otp`;

export const sendOTPURL = `${userAPIs}/send-otp`;

export const getUserProfileURL = `${userAPIs}/user-profile`;

export const getUserProfilePicURL = `${host}/api/services/cf-url?`;

export const updateProfileURL = `${userAPIs}/update-profile`;

export const deleteUserProfileURL = `${host}/api/users/delete`;

// My-Travels
export const upcomingTripsURL = `${host}/api/users/flight-upcoming-trips`;

export const completedTripsURL = `${host}/api/users/flight-completed-trips`;

export const canceledTripsURL = `${host}/api/users/flight-cancelled-trips`;

export const cancelBookingURL = `${host}/api/flights/cancel-flight`;

//export const canceledTripsURL = `${host}/api/users/flight-cancelled-trips`;

// Flight

export const webSettingsURL = `${host}/api/flights/web-settings`;

export const getGeneratedSignedTokenURL = `${host}/api/flights/gen-sign`;

export const getAllAirportsURL = `${host}/api/flights/airport-search?allAirport=1&search=`;

export const getCurrentLocationAirportURL = `${host}/api/flights/airport-search?allAirport=0`;

export const expressSearchURL = `${host}/api/flights/exp-flight`;

export const getTUITokenForExpressSearchURL = `${host}/api/flights/get-exp-flight`;

export const getFlightInfoURL = `${host}/api/flights/flight-info`;

export const getFlightFareURL = `${host}/api/flights/flight-fare`;

export const getFlightBaggageURL = `${host}/api/flights/ssr`;

export const smartPriceForTokenURL = ` ${host}/api/flights/smart-price`;
export const getSmartPriceURL = `${host}/api/flights/get-smart-price`;

export const getSSRforBookingURL = `${host}/api/flights/ssr`;

export const getSeatLayoutURL = `${host}/api/flights/seat-layout`;

export const checkChangedFareURL = ` ${host}/api/flights/get-travel-check-list`;

export const flightItineraryURL = `${host}/api/flights/create-flight-itinerary`;

export const saveFlightGuestURL = `${host}/api/users/save-guest`;

export const flightPaymentPayuURL = `${host}/api/payments/paynow`;

export const flightStartPayURL = `${host}/api/flights/flight-start-pay`;

export const storeFlightDataURL = `${host}/api/flights/store-data`;

export const flightItineraryStatusURL = `${host}/api/flights/get-itinerary-status`;

export const retrieveBookingURL = `${host}/api/flights/retrieve-booking`;

export const updateFlightStoreDataURL = `${host}/api/flights/update-store-data`;

export const downloadBookingURL = `${host}/api/flights/download-ticket`;

export const sendSMSForBookingURL = `${host}/api/flights/send-email-sms`;

// export const getFlightInfoURL =
//   "https://b2bstageapi.benzyinfotech.com/flights/GetExpSearch";

// Hotel

export const hotelAdminURL = `${host}/api/hotels/admin`;

export const hotelContentURL = `${host}/api/hotels/content-hotel`;

export const hotelMetaDataURL = `${host}/api/hotels/metadata-list`;

export const destinationHotelURL = `${host}/api/hotels/destination-list`;

export const hotelRoomListURL = `${host}/api/hotels/room-list`;

export const hotelCategoryList = `${host}/api/hotels/category-list`;

export const contentBoardURL = `${host}/api/hotels/board-list`;

export const hotelListURL = `${host}/api/hotels/hotel-list`;

export const hotelSearchURL = `${host}/api/hotels/search`;

export const upToDatePriceBookingURL = `${host}/api/hotels/quote`;

export const bookingHotelURL = `${host}/api/hotels/book`;

export const bookingListHotelURL = `${host}/api/hotels/booking-list`;

export const bookingDetailsURL = `${host}/api/hotels/booking-details`;

export const bookingSearchHotelURL = `${host}/api/hotels/search`;

export const validatePanCardURL = `${host}/api/hotels/validate-pancard`;
